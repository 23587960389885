import { AdvertisingWidgetDto } from 'types/Types';
import styles from './ImageInCenterTypeWidget.module.scss';
import { getURI } from 'api/helper';
import { isContainsHTMLTags } from 'helpers/helpers';

type ImageInCenterTypeWidgetType = {
  advertising: AdvertisingWidgetDto;
};

function ImageInCenterTypeWidget({ advertising }: ImageInCenterTypeWidgetType) {
  return (
    <div className={styles.imageInCenterContainer}>
      <div className={styles.innerTextContainer}>
        <div className={styles.innerText}>
          {isContainsHTMLTags(advertising.attributes.text) ? (
            <div
              dangerouslySetInnerHTML={{
                __html: advertising.attributes.text || '',
              }}
            />
          ) : (
            advertising.attributes.text
          )}
        </div>
      </div>
      <div className={styles.imageInCenterLogoContainer}>
        <img
          src={getURI(
            advertising.attributes?.main_image?.data.attributes.url || ''
          )}
          className={styles.imageInCenterLogo}
          style={{ margin: advertising.attributes.padding }}
        />
      </div>
      <div className={styles.containerButton}>
        <a
          className={styles.widgetBtn}
          target='_blank'
          href={advertising.attributes.link}
        >
          <div className={styles.alignBtn}>
            <span className={styles.widgetBtnBuy}>Заказать</span>
          </div>
        </a>
      </div>
    </div>
  );
}

export default ImageInCenterTypeWidget;
