import { observer } from 'mobx-react-lite';
import {
  GameProtocolDto,
  PenaltyDto,
  ProtocolGameSummaryDto,
  ProtocolTeamDatawithPlayerStats,
} from '../../../../../../types/Types';
import styles from './TeamStatsTable.module.scss';
import { useEffect, useState } from 'react';
import { getURI } from '../../../../../../api/helper';

type teamGameData = {
  shots: number | string;
  hits: number | string;
  bls: number | string;
  toa: number | string;
  enemyPenaltiesCount: number;
  penaltiesDuratuin: number;
  goalsCount: number;
  powerGoalsCount: number;
  teamName: string;
  teamCity: string;
  teamLogo: string;
};

type TeamStatsTableProps = {
  teamsData: ProtocolTeamDatawithPlayerStats[];
  protocol: GameProtocolDto;
};

const TeamStatsTable = observer(
  ({ teamsData, protocol }: TeamStatsTableProps) => {
    const [teams, setTeams] = useState<teamGameData[]>();

    useEffect(() => {
      const result = teamsData?.map((team, index) => {
        const teamIndex = index === 0 ? 'A' : 'B';
        const enemyTeam = index === 0 ? 'B' : 'A';

        const shots = getSummary(
          protocol.GameSummary?.ShotsList?.Shot?.find(
            (item) => item && item['@_team'] === teamIndex
          )
        );
        const hits = getSummary(
          protocol.GameSummary?.HitsList?.Hit?.find(
            (item) => item && item['@_team'] === teamIndex
          )
        );
        const bls = getSummary(
          protocol.GameSummary?.BlsList?.Bls?.find(
            (item) => item && item['@_team'] === teamIndex
          )
        );
        const toa = getSummary(
          protocol.GameSummary?.TimeOnAttack?.Toa?.find(
            (item) => item && item['@_team'] === teamIndex
          )
        );

        const penaltiesDto = protocol.GameSummary?.Penalties.find(
          (item) => item && item['@_team'] === teamIndex
        );

        let penaltyTimes: { time: string; duration: string; code: string }[] =
          [];

        if (Array.isArray(penaltiesDto?.Penalty)) {
          penaltyTimes =
            penaltiesDto?.Penalty.map((p) => {
              return {
                time: p['@_tbeg'],
                duration: p['@_time'],
                code: p['@_code'],
              };
            }) || [];
        } else {
          penaltyTimes =
            penaltiesDto?.Penalty && penaltiesDto?.Penalty['@_tbeg']
              ? [
                  {
                    time: penaltiesDto?.Penalty['@_tbeg'],
                    duration: penaltiesDto?.Penalty['@_time'],
                    code: penaltiesDto?.Penalty['@_code'],
                  },
                ]
              : [];
        }

        const enemyPenaltiesDto = protocol.GameSummary?.Penalties.find(
          (item) => item && item['@_team'] === enemyTeam
        );

        const enemyPenaltiesCount =
          enemyPenaltiesDto && enemyPenaltiesDto.Penalty
            ? calculateMajoritiesCount(penaltyTimes, enemyPenaltiesDto.Penalty)
            : 0;

        const penaltiesDuratuin =
          penaltiesDto && penaltiesDto.Penalty
            ? Array.isArray(penaltiesDto.Penalty)
              ? penaltiesDto.Penalty.reduce((sum, penalty) => {
                  return sum + Number(penalty['@_time']);
                }, 0)
              : Number(penaltiesDto.Penalty['@_time'])
            : 0;

        const goalsData = Array.isArray(protocol.GameSummary?.Goals.Goal)
          ? protocol.GameSummary?.Goals.Goal
          : [protocol.GameSummary?.Goals.Goal];
        const goals = goalsData.filter(
          (item) => (item && item['@_teamId']) === team.inGameId
        );
        const goalsCount = goals ? goals.length : 0;
        const powerGoalsCount = goals
          ? goals.filter((item) => parseInt(item && item['@_pmg']) > 0).length
          : 0;

        return {
          shots,
          hits,
          bls,
          toa,
          enemyPenaltiesCount,
          penaltiesDuratuin,
          goalsCount,
          powerGoalsCount,
          teamName: team.name,
          teamCity: team.city,
          teamLogo: team.logo,
        };
      });

      setTeams(result);
    }, [teamsData]);

    const calculateMajoritiesCount = (
      times: { time: string; duration: string; code: string }[],
      enemyPenalties: PenaltyDto[] | PenaltyDto
    ) => {
      if (!Array.isArray(enemyPenalties)) {
        return checkPenaltiesForMajority(enemyPenalties, times) ? 1 : 0;
      }

      const majorities: PenaltyDto[] = [];

      enemyPenalties.forEach((penalty) => {
        if (
          penalty['@_code'] === '39' ||
          penalty['@_code'] === '40' ||
          penalty['@_time'] === '0'
        ) {
          return;
        }

        const sameTimePenalty = times.filter(
          (t) =>
            t.time === penalty['@_tbeg'] &&
            !(t.code === '40' || t.code === '39')
        );

        const enemySameTimePenalty = enemyPenalties.filter(
          (item) =>
            item['@_tbeg'] === penalty['@_tbeg'] &&
            !(item['@_code'] === '40' || item['@_code'] === '39')
        );

        if (
          (enemySameTimePenalty.length > 1 &&
            !majorities.find((item) => item['@_tbeg'] === penalty['@_tbeg'])) ||
          !sameTimePenalty.some(
            (item) => parseInt(item?.duration) >= parseInt(penalty['@_time'])
          )
        ) {
          majorities.push(penalty);
        }
      });

      return majorities.length;
    };

    const checkPenaltiesForMajority = (
      penalty: PenaltyDto,
      times: { time: string; duration: string; code: string }[]
    ) => {
      if (
        penalty['@_code'] === '39' ||
        penalty['@_code'] === '40' ||
        penalty['@_time'] === '0'
      ) {
        return false;
      }

      const sameTimePenalty = times.filter(
        (t) =>
          t.time === penalty['@_tbeg'] && !(t.code === '40' || t.code === '39')
      );

      return !sameTimePenalty.some(
        (item) => parseInt(item?.duration) >= parseInt(penalty['@_time'])
      );
    };

    const getSummary = (
      data: ProtocolGameSummaryDto | undefined
    ): number | string => {
      if (!data) {
        return '-';
      }

      return data['@_summary']
        ? data['@_summary']
        : Number(data['@_p1']) +
            Number(data['@_p2']) +
            Number(data['@_p3']) +
            Number(data['@_ot']);
    };

    const renderRows = () => {
      if (teams) {
        return teams.map((team) => {
          return (
            <tr className={styles.tableRow} key={team.teamName}>
              <td>
                <div className={styles.team}>
                  <div className={styles.score}>{team.goalsCount}</div>
                  <img
                    className={styles.logo}
                    src={getURI(team.teamLogo)}
                    alt=''
                  />
                  <div className={styles.nameAndCity}>
                    <div className={styles.name}>{team.teamName}</div>
                    <div className={styles.city}>{team.teamCity}</div>
                  </div>
                </div>
              </td>
              <td>{team.shots}</td>
              <td
                className={styles.goals}
              >{`${team.powerGoalsCount} / ${team.enemyPenaltiesCount}`}</td>
              <td>{team.penaltiesDuratuin}</td>
              <td>{team.hits}</td>
              <td>{team.bls}</td>
              {teams &&
                !teams.every((team) => !team.toa || team.toa === '-') && (
                  <td>{team.toa}</td>
                )}
            </tr>
          );
        });
      }

      return null;
    };

    return (
      <table className={styles.table}>
        <tbody className={styles.tableBody}>
          <tr className={styles.tableHeader}>
            <th className={styles.firstTh}>{}</th>
            <th title={'Броски в створ'}>БР</th>
            <th title={'Реализация большинства'}>Бол</th>
            <th title={'Штрафное время'}>Штр</th>
            <th title={'Силовые приемы'}>СПр</th>
            <th title={'Блокированные броски'}>БлБ</th>
            {teams && !teams.every((team) => !team.toa || team.toa === '-') && (
              <th title={'Время в атаке'}>ВВА</th>
            )}
          </tr>
          {renderRows()}
        </tbody>
      </table>
    );
  }
);

export default TeamStatsTable;
