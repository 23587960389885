import { ReactElement } from 'react';
import { getURI } from '../../../api/helper';
import { File } from '../../../types/Types';
import styles from './PlayerPhoto.module.scss';

type PlayerPhoto = {
  image: File;
  openAlbum: Function;
  index: number;
};

function PlayerPhoto({ image, openAlbum, index }: PlayerPhoto): ReactElement {
  return (
    <div className={styles.photoWrapper}>
      <div
        key={image.id}
        onClick={() => openAlbum(index)}
        className={styles.photo}
        style={{ backgroundImage: `url('${getURI(image.url)}'` }}
      />
    </div>
  );
}

export default PlayerPhoto;
