import { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import { getURI } from '../../../../api/helper';
import { getNoun } from '../../../../helpers/helpers';
import { Roster, TopPlayerCardType } from '../../../../types/Types';
import {
  CATEGORY_NOUNS,
  HEADER_FOR_CATEGORY,
  POSITION_MAP,
} from '../../../../constants/constants';
import styles from './TopPlayerCard.module.scss';
import { useStore } from '../../../../store/store';
import { observer } from 'mobx-react-lite';
import { getPlayerByIdLeague } from 'api/api';
import Spinner from 'components/Spinner/Spinner';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';

type TopPlayerCardProps = {
  player: TopPlayerCardType | undefined;
  defaultImg: string;
};

const TopPlayerCard = observer(
  ({ player, defaultImg }: TopPlayerCardProps): ReactElement => {
    const { currentSeasonStore } = useStore();
    const [isHover, setIsHover] = useState(false);
    const [season, setSeason] = useState<string>('');
    const [playerNumber, setPlayerNumber] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
      if (currentSeasonStore.currentSeason?.men) {
        setSeason(currentSeasonStore.currentSeason?.men);
      }
    }, [currentSeasonStore.currentSeason]);

    useEffect(() => {
      if (player) {
        getLeaders();
      }
    }, [player, season]);

    const getLeaders = async () => {
      try {
        const playerData = await getPlayerByIdLeague(
          player?.player?.attributes.id_league_web
        );
        const playerNumberResponse = playerData.data.flatMap((item: any) =>
          item.attributes.rosters.data
            .filter(
              (r: Roster) => r.attributes.season.data.attributes.name === season
            )
            .map((r: Roster) => r.attributes.number)
        );
        setPlayerNumber(playerNumberResponse[0] ?? player?.stats?.number ?? 0);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    function getImg() {
      if (!player) {
        return;
      }
      return getURI(
        player.player?.attributes?.photo?.data?.attributes?.formats?.small
          ?.url ||
          player.player?.attributes?.photo?.data?.attributes?.url ||
          defaultImg ||
          ''
      );
    }

    const getCategoryNouns = (value: number, category: string): string => {
      const nounsArray = CATEGORY_NOUNS.get(category);

      return nounsArray
        ? getNoun(value || 0, nounsArray[0], nounsArray[1], nounsArray[2])
        : category;
    };

    if (isError) {
      return <ErrorWidget.Error />;
    }
    if (isLoading) {
      return <Spinner />;
    }
    return (
      <a
        href={`/player/${player?.player?.id}?season=${season}`}
        className={styles.topPlayerLink}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <div className={styles.topPlayerWrapper}>
          <span className={styles.topPlayerHeader}>
            {player && HEADER_FOR_CATEGORY.get(player.category)}
          </span>
          <div
            className={classNames(
              player &&
                HEADER_FOR_CATEGORY.get(player.category) === 'Бомбардиры'
                ? styles.cardBombardier
                : styles.cardWrapper,
              isHover && classNames(styles.redColor)
            )}
          >
            <div className={styles.cardPlayer}>
              <div
                className={classNames(styles.imgWrapper, styles.number)}
                data-number={playerNumber}
              >
                <img
                  src={getImg()}
                  className={styles.cardPlayerImg}
                  alt={player?.player?.attributes.name}
                />
              </div>
              <span className={styles.cardPlayerName}>
                <div
                  className={
                    isHover
                      ? classNames(styles.topPlayerName, styles.whiteText)
                      : styles.topPlayerName
                  }
                >
                  {player && `${player.player?.attributes.name} `}
                  {player && player.player?.attributes.surname}
                </div>
                <div
                  className={
                    isHover
                      ? classNames(styles.topPlayerCategory, styles.whiteText)
                      : styles.topPlayerCategory
                  }
                >
                  {POSITION_MAP.get(
                    player?.player?.attributes?.position?.data?.id
                  )}
                </div>
              </span>
            </div>
            {player &&
            HEADER_FOR_CATEGORY.get(player.category) === 'Бомбардиры' ? (
              <table>
                <tr className={styles.statisticGoals}>
                  <td></td>
                  <td valign='baseline'>
                    <span
                      className={
                        isHover
                          ? classNames(styles.dopValueNumber, styles.whiteText)
                          : styles.dopValueNumber
                      }
                    >
                      {player && player.stats?.goals}
                    </span>
                    <span
                      className={
                        isHover
                          ? classNames(styles.dopValueText, styles.whiteText)
                          : styles.dopValueText
                      }
                    >
                      {getNoun(
                        player.stats?.goals || 0,
                        'ШАЙБА',
                        'ШАЙБЫ',
                        'ШАЙБ'
                      )}
                    </span>
                  </td>
                </tr>
                <tr className={styles.statisticRow}>
                  <td valign='baseline'>
                    <span
                      className={
                        isHover
                          ? classNames(styles.topPlayerNumber, styles.whiteText)
                          : styles.topPlayerNumber
                      }
                    >
                      {player && player.value}
                    </span>
                    <span
                      className={
                        isHover
                          ? classNames(styles.topText, styles.whiteText)
                          : styles.topText
                      }
                    >
                      {player &&
                        player.category &&
                        getCategoryNouns(player.value, player.category)}
                    </span>
                  </td>
                  <td valign='baseline'>
                    <span
                      className={
                        isHover
                          ? classNames(styles.dopValueNumber, styles.whiteText)
                          : styles.dopValueNumber
                      }
                    >
                      {player && player.stats?.assists}
                    </span>
                    <span
                      className={
                        isHover
                          ? classNames(styles.dopValueText, styles.whiteText)
                          : styles.dopValueText
                      }
                    >
                      {getNoun(
                        player.stats?.assists || 0,
                        'ПЕРЕДАЧА',
                        'ПЕРЕДАЧИ',
                        'ПЕРЕДАЧ'
                      )}
                    </span>
                  </td>
                </tr>
              </table>
            ) : (
              <table>
                <tr>
                  <td>
                    <span className={styles.opacity}>opacity 0</span>
                  </td>
                  <td>
                    <span className={styles.opacity}>opacity 0</span>
                  </td>
                </tr>
                <tr className={styles.statisticGoals}>
                  <td valign='baseline'>
                    <span
                      className={
                        isHover
                          ? classNames(styles.topPlayerNumber, styles.whiteText)
                          : styles.topPlayerNumber
                      }
                    >
                      {player && player.value}
                    </span>
                    <span
                      className={
                        isHover
                          ? classNames(styles.topText, styles.whiteText)
                          : styles.topText
                      }
                    >
                      {player &&
                        player.category &&
                        getCategoryNouns(player.value, player.category)}
                    </span>
                  </td>
                  <td valign='baseline'>
                    <span className={styles.opacity}>opacity 0</span>
                  </td>
                </tr>
              </table>
            )}
          </div>
        </div>
      </a>
    );
  }
);

export default TopPlayerCard;
