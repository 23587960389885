import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getStaticPageById } from '../../api/api';
import { AddOns, MediaSlideProps, StaticPageDto } from '../../types/Types';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import PageHeader from 'components/common/PageHeader/PageHeader';
import ImageBgPageWrapper from '../common/ImageBgPageWrapper/ImageBgPageWrapper';
import Container from '../common/Container/Container';
import SideMenu from '../common/SideMenu/SideMenu';
import Flags from './Flags/Flags';
import { getURI } from '../../api/helper';
import MediaSlider from './MediaSlider/MediaSlider';
import ShowOn from 'components/core/adaptivity/ShowOn';
import styles from './StaticPage.module.scss';
import ShareInSocials from 'components/common/ShareInSocials/ShareInSocials';
import Spinner from 'components/Spinner/Spinner';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import Image from 'model/entity/loko/type/Image';
import { useTranslation } from 'react-i18next';
import SetRespError, { addTargetBlank } from 'helpers/helpers';
import ErrorPage from '../pages/ErrorPage/ErrorPage';

const MAX_TEXT_CONTENT_HEIGHT = 1000;

const StaticPage = observer(() => {
  const { t } = useTranslation();
  const { staticPageId } = useParams();
  const textRef = useRef<HTMLDivElement>(null);

  const [staticPage, setStaticPage] = useState<StaticPageDto>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [isNotFoundError, setIsNotFoundError] = useState<boolean>(false);
  const [media, setMedia] = useState<MediaSlideProps[] | null>(null);
  const [isFullTextVisible, setIsFullTextVisible] = useState<boolean>(true);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      try {
        if (staticPageId) {
          const response = await getStaticPageById(staticPageId);

          setStaticPage(response?.data);

          const slider = response?.data?.attributes?.slider ?? null;

          if (!slider) {
            return;
          }

          const mediaSlides =
            slider.media?.data?.map((item: Image) => ({
              url: getURI(item.attributes.url) ?? '',
              isVideo: item.attributes.ext === '.mp4',
              title: item.attributes.name ?? '',
              isYoutube: false,
            })) ?? [];

          if (slider.youtube_urls?.length) {
            slider.youtube_urls.map((item: { url: string }) =>
              mediaSlides.push({
                url: item.url.replace('watch?v=', 'embed/'),
                isVideo: true,
                title: '',
                isYoutube: true,
              })
            );
          }

          setMedia(mediaSlides);
        }
      } catch (error) {
        SetRespError(error, setIsNotFoundError, setIsError);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [staticPageId]);

  useEffect(() => {
    if (textRef.current && !isMounted) {
      setIsFullTextVisible(
        textRef.current.clientHeight <= MAX_TEXT_CONTENT_HEIGHT
      );
      setIsMounted(true);
    }
  }, [staticPage]);

  function formatText(details: string) {
    return details
      .replaceAll(
        '<figure class="table">',
        `<figure class="table" style="display: flex; align-items: center; justify-content: center;">`
      )
      .replaceAll('<blockquote>', `<blockquote style="font-style: italic;">`);
  }

  const renderAchievements = useMemo(
    () => (isSlider: boolean, slidesToShow?: number) => {
      const addOn = staticPage?.attributes?.add_ons?.find(
        (addOn) => addOn.name === AddOns.achievements
      );

      if (!addOn) {
        return null;
      }

      return (
        <div className={styles.achievementsWrapper}>
          <div className={styles.historyWrapper}>
            <h4
              className={classNames(styles.title, styles.upperCase, {
                [styles.black]: isSlider,
              })}
            >
              {t('staticPage.achievements')}
            </h4>
            <Flags isSlider={isSlider} slidesToShow={slidesToShow} />
          </div>
        </div>
      );
    },
    [staticPage]
  );

  const render = () => (
    <Container paddingTop='50px'>
      <div className={styles.wrapper}>
        {staticPage && (
          <>
            <BreadcrumbsWrapper
              breadcrumbs={[
                {
                  name: staticPage?.attributes.Title ?? '',
                  url: `/static-page/${staticPageId}`,
                },
              ]}
            />
            <div className={styles.headerWrapper}>
              <PageHeader
                text={staticPage?.attributes.Title}
                paddingBottom={staticPage?.attributes?.subtitle ? 30 : 50}
              />
              {staticPage?.attributes?.subtitle ? (
                <h2 className={styles.subtitle}>
                  {staticPage.attributes.subtitle}
                </h2>
              ) : null}
            </div>
            <div className={styles.contentWrapper}>
              <div
                className={classNames(styles.content, {
                  [styles.hideAd]: staticPage?.attributes.hide_ad,
                })}
              >
                <h4>{staticPage?.attributes.Annotation}</h4>
                {media?.length ? (
                  <>
                    <ShowOn
                      largeDesktop
                      smallDesktop
                      largeTablet
                      smallTablet
                      largeMobile
                    >
                      <MediaSlider media={media} width='100%' height='500px' />
                    </ShowOn>
                    <ShowOn smallMobile>
                      <MediaSlider media={media} width='100%' height='400px' />
                    </ShowOn>
                  </>
                ) : null}
                <div
                  ref={textRef}
                  className={classNames(styles.text, {
                    [styles.collapsed]: !isFullTextVisible,
                  })}
                  dangerouslySetInnerHTML={{
                    __html:
                      addTargetBlank(formatText(staticPage?.attributes.Text)) ??
                      '',
                  }}
                />
                {!isFullTextVisible && (
                  <button
                    className={styles.showMoreButton}
                    onClick={() => setIsFullTextVisible(!isFullTextVisible)}
                  >
                    {t('staticPage.readMore')}
                  </button>
                )}
                {staticPage.attributes.show_share_in_socials && (
                  <div className={styles.shareWrapper}>
                    <ShareInSocials />
                  </div>
                )}
              </div>
              <div>
                <ShowOn smallMobile largeMobile>
                  {renderAchievements(true, 1)}
                </ShowOn>
                <ShowOn smallTablet largeTablet>
                  {renderAchievements(true, 3)}
                </ShowOn>
              </div>
              {!staticPage?.attributes.hide_ad && (
                <div className={styles.sideMenu}>
                  <SideMenu />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <ShowOn smallDesktop largeDesktop>
        {renderAchievements(false)}
      </ShowOn>
    </Container>
  );

  return (
    <ImageBgPageWrapper>
      {isLoading ? (
        <Spinner />
      ) : isNotFoundError ? (
        <ErrorWidget.NotFound isOnDarkBackground />
      ) : isError || !staticPage ? (
        <ErrorWidget.Error isOnDarkBackground />
      ) : (
        render()
      )}
    </ImageBgPageWrapper>
  );
});

export default StaticPage;
