import { ReactElement } from 'react';
import { getURI } from '../../../../api/helper';
import { DAYS_SHORT_OF_WEEK } from '../../../../constants/constants';
import { ScheduleDay } from '../../../../types/Types';
import styles from './Day.module.scss';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import HoverGame from '../HoverGame/HoverGame';
import {
  checkScore,
  getScore,
  getTwoDigitNumber,
} from '../../../../helpers/helpers';
import { DayType } from './DayType';

type SlickDayProps = {
  slickDay: ScheduleDay;
  isCalendarPage: boolean;
  type: DayType;
  isHover?: boolean;
};

function Day({
  slickDay,
  isCalendarPage = false,
  type,
}: SlickDayProps): ReactElement {
  const getImageLokomotiv = () =>
    getURI(
      slickDay.game?.attributes.team_1?.data?.attributes.logo?.data?.attributes
        .url || ''
    );

  const getImageOpponent = () =>
    getURI(
      slickDay.game?.attributes.team_2?.data?.attributes.logo?.data?.attributes
        .url || ''
    );

  return (
    <>
      {
        <div className={classNames(styles.dayWrapper, styles[`day_${type}`])}>
          {isCalendarPage ? (
            type === 'default' ? (
              <div className={styles.day_page}>
                <div
                  className={classNames(
                    styles.dateNumber,
                    styles.dateNumber_page
                  )}
                >
                  {getTwoDigitNumber(slickDay.day.getDate().toString())}
                </div>
                <div className={styles.dateName_page}>
                  {DAYS_SHORT_OF_WEEK.get(slickDay.day.getDay()).toUpperCase()}
                </div>
              </div>
            ) : type === 'isHome' ? (
              <Tooltip
                placement='top'
                title={
                  slickDay.game && (
                    <HoverGame game={slickDay.game} isHome={true} />
                  )
                }
                color={'white'}
                overlayInnerStyle={{ padding: '0px', boxShadow: 'none' }}
              >
                <div className={styles.day_page}>
                  <div
                    className={classNames(styles.red, styles.dateNumber_page)}
                  >
                    {getTwoDigitNumber(slickDay.day.getDate().toString())}
                  </div>
                  <div className={classNames(styles.red, styles.dateName_page)}>
                    {DAYS_SHORT_OF_WEEK.get(
                      slickDay.day.getDay()
                    ).toUpperCase()}
                  </div>
                  {slickDay.game && (
                    <div className={styles.gameWrapper}>
                      <div className={styles.logoWrapper}>
                        <img
                          className={styles.customLogo}
                          src={getImageLokomotiv()}
                          alt='lokomotiv logo'
                        />
                      </div>
                      <span
                        className={
                          checkScore(slickDay.game.attributes.score_1) &&
                          checkScore(slickDay.game.attributes.score_2)
                            ? classNames(styles.red, styles.divider_page)
                            : styles.divider_page
                        }
                      >
                        {getScore(slickDay.game.attributes.score_1)}:
                        {getScore(slickDay.game.attributes.score_2)}
                      </span>
                      <div className={styles.logoWrapper}>
                        <img
                          className={styles.customLogo}
                          src={getImageOpponent()}
                          alt='opponent logo'
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Tooltip>
            ) : type === 'isAway' ? (
              <Tooltip
                placement='top'
                title={
                  slickDay.game && (
                    <HoverGame game={slickDay.game} isHome={false} />
                  )
                }
                color={'white'}
                overlayInnerStyle={{ padding: '0px', boxShadow: 'none' }}
              >
                <div className={styles.day_page}>
                  <div
                    className={classNames(styles.blue, styles.dateNumber_page)}
                  >
                    {getTwoDigitNumber(slickDay.day.getDate().toString())}
                  </div>
                  <div
                    className={classNames(styles.blue, styles.dateName_page)}
                  >
                    {DAYS_SHORT_OF_WEEK.get(
                      slickDay.day.getDay()
                    ).toUpperCase()}
                  </div>
                  {slickDay.game && (
                    <div className={styles.gameWrapper}>
                      <div className={styles.logoWrapper}>
                        <img
                          className={styles.customLogo}
                          src={getImageLokomotiv()}
                          alt='lokomotiv logo'
                        />
                      </div>
                      <span
                        className={
                          checkScore(slickDay.game.attributes.score_1) &&
                          checkScore(slickDay.game.attributes.score_2)
                            ? classNames(styles.blue, styles.divider_page)
                            : styles.divider_page
                        }
                      >
                        {getScore(slickDay.game.attributes.score_1)}:
                        {getScore(slickDay.game.attributes.score_2)}
                      </span>
                      <div className={styles.logoWrapper}>
                        <img
                          className={styles.customLogo}
                          src={getImageOpponent()}
                          alt='opponent logo'
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Tooltip>
            ) : (
              <div className={styles.day_page}>
                <div
                  className={classNames(
                    styles.dateNumber_notCurrent,
                    styles.dateNumber_page
                  )}
                >
                  {getTwoDigitNumber(slickDay.day.getDate().toString())}
                </div>
                <div
                  className={classNames(
                    styles.dateNumber_notCurrent,
                    styles.dateName_page
                  )}
                >
                  {DAYS_SHORT_OF_WEEK.get(slickDay.day.getDay()).toUpperCase()}
                </div>
              </div>
            )
          ) : (
            <></>
          )}
        </div>
      }
    </>
  );
}

export default Day;
