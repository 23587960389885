import { ReactElement } from 'react';
import ExecutiveTile from '../ExecutiveTile/ExecutiveTile';
import { ExecutiveDto } from '../../types/Types';
import styles from './ExecutiveTiles.module.scss';
import ExecutiveTileMobile from 'components/ExecutiveTileMobile/ExecutiveTileMobile';

type ExecutiveTilesProps = {
  executives: ExecutiveDto[];
  season?: string;
};

function ExecutiveTiles({
  executives,
  season,
}: ExecutiveTilesProps): ReactElement {
  return (
    <>
      {executives?.length > 2 ? (
        <div className={styles.wrapper}>
          {executives?.map((executive) => (
            <ExecutiveTile
              executive={executive}
              season={season}
              key={executive?.id}
            />
          ))}
        </div>
      ) : (
        <div className={styles.wrapperMobile}>
          {executives?.map((executive) => (
            <ExecutiveTileMobile
              executive={executive}
              season={season}
              key={executive?.id}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default ExecutiveTiles;
