import { ReactElement } from 'react';
import { getDateInfo } from '../../../helpers/helpers';
import styles from './BookletItem.module.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Booklet from '../../../assets/program-icon.png';
import { getURI } from '../../../api/helper';
import Article from 'model/entity/loko/type/Article';
import { useTranslation } from 'react-i18next';

type BookletItemProps = {
  booklet: Article;
  isSmall?: boolean;
};

const BookletItem = ({
  booklet,
  isSmall = false,
}: BookletItemProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Link
      to={`/booklet/${booklet.id}`}
      className={classNames(styles.bookletItem, {
        [styles.smallBookletItem]: isSmall,
      })}
    >
      <div className={styles.content}>
        <div className={styles.imageBlock}>
          {isSmall && <div className={styles.badge}>{t('bookletsPage.item.badge')}</div>}
          {!isSmall && (
            <div>
              <div className={styles.badge}>{t('bookletsPage.item.badge')}</div>
              <h2
                className={classNames(styles.title, {
                  [styles.small]: isSmall,
                })}
              >
                {booklet.attributes.title}
              </h2>
              <p className={isSmall ? styles.dateSmall : styles.date}>
                {getDateInfo(booklet)}
              </p>
            </div>
          )}
          <div
            className={classNames(
              isSmall ? styles.imageContainerSmall : styles.imageContainer
            )}
          >
            <img
              className={styles.image}
              src={
                booklet?.attributes?.preview_image?.data
                  ? getURI(
                      booklet.attributes.preview_image.data.attributes.url
                    )
                  : Booklet
              }
              alt='booklet-img'
            />
          </div>
        </div>
        {isSmall ? (
          <>
            <h2
              className={classNames(styles.title, { [styles.small]: isSmall })}
            >
              {booklet.attributes.title}
            </h2>
            <p className={isSmall ? styles.dateSmall : styles.date}>
              {getDateInfo(booklet)}
            </p>
          </>
        ) : null}
      </div>
    </Link>
  );
};

export default BookletItem;
