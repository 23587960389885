import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/store';
import styles from './EditProfileModal.module.scss';
import { getURI } from 'api/helper';
import { Input, notification } from 'antd';
import classNames from 'classnames';
import { updateProfile, uploadImage } from 'api/api';
import FileUploadButton from 'components/common/FileUploadButton/FileUploadButton';
import CloseIcon from 'assets/icons/close-icon.svg';
import { validateEmail } from 'helpers/helpers';
import { useTranslation } from 'react-i18next';

type EditProfileModalProps = {
  onCancel: () => void;
};

const getFormData = (userId: string, file: File) => {
  let fd = new FormData();
  fd.append('files', file);
  fd.append('ref', 'plugin::users-permissions.user');
  fd.append('refId', userId);
  fd.append('field', 'avatar');
  return fd;
};

const EditProfileModal = observer(
  ({ onCancel }: EditProfileModalProps): ReactElement => {
    const { userStore } = useStore();
    const { t } = useTranslation();
    const [login, setLogin] = useState('');
    const [nickname, setNickname] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [city, setCity] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isPasswordsMatch, setIsPasswordsMatch] = useState(true);
    const [avatar, setAvatar] = useState<FormData>();
    const [imageBytes, setImageBytes] = useState<any>();

    useEffect(() => {
      setLogin(userStore.currentUser?.username || '');
      setNickname(userStore.currentUser?.nickname || '');
      setEmail(userStore.currentUser?.email || '');
      setName(userStore.currentUser?.name || '');
      setSurname(userStore.currentUser?.surname || '');
      setCity(userStore.currentUser?.city || '');
      setPassword(userStore.currentUser?.password || '');
    }, [userStore.currentUser]);

    const onEmailChange = (value: string) => {
      setEmail(value);

      if (!value || validateEmail(value)) {
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
    };

    const onConfirmPasswordChange = (value: string) => {
      setConfirmPassword(value);
      setIsPasswordsMatch(true);
    };

    const setAvatarState = (e: ChangeEvent<HTMLInputElement>) => {
      const file = e?.target?.files ? e?.target?.files[0] : undefined;
      if (file && userStore.currentUser?.id) {
        const fd = getFormData(userStore.currentUser?.id.toString(), file);
        setAvatar(fd);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          setImageBytes(e.target?.result);
        };
      }
    };

    const removeAvatarSelection = () => {
      setAvatar(undefined);
      setImageBytes(undefined);
    };

    const onCancelClick = () => {
      onCancel();
    };

    const onSaveClick = async () => {
      if (password !== confirmPassword) {
        setIsPasswordsMatch(false);
        return;
      }

      try {
        let avatarUploadResponse;
        if (avatar) {
          avatarUploadResponse = await uploadImage(
            avatar,
            userStore.getJWT() || ''
          );
        }
        const data = {
          username: login,
          nickname,
          email: email ? email : undefined,
          name,
          surname,
          password: password ? password : undefined,
          city: city ? city : undefined,
          avatar: avatarUploadResponse?.data[0]?.id
            ? avatarUploadResponse?.data[0]?.id
            : undefined,
        };

        await updateProfile(data, userStore.getJWT());

        notification.success({
          message: t('memoryBookPage.editProfileModal.dataSaved'),
          placement: 'bottomLeft',
          duration: 15,
        });

        onCancel();
      } catch (e: any) {
        const error = e?.response?.data?.error || e?.message;
        console.log(error);
        if (error && error.message === 'Username already taken') {
          notification.error({
            message: t(
              'memoryBookPage.editProfileModal.userWithLoginAlreadyExist'
            ),
            placement: 'bottomLeft',
            duration: 15,
          });
        } else if (error && error.message === 'Email already taken') {
          notification.error({
            message: t(
              'memoryBookPage.editProfileModal.userWithEmailAlreadyExist'
            ),
            placement: 'bottomLeft',
            duration: 15,
          });
        } else if (
          error &&
          error.message === 'password must be at least 6 characters'
        ) {
          notification.error({
            message: t(
              'memoryBookPage.editProfileModal.saveFailedPasswordLessThan'
            ),
            placement: 'bottomLeft',
            duration: 15,
          });
        } else {
          notification.error({
            message: t('memoryBookPage.editProfileModal.saveFailed'),
            placement: 'bottomLeft',
            duration: 15,
          });
        }
      } finally {
        userStore.loadUserInfo();
      }
    };

    return (
      <div className={styles.editProfileModal}>
        <div className={styles.header}>
          {t('memoryBookPage.editProfileModal.header')}
        </div>
        <div className={styles.inputsBlock}>
          <div className={styles.inputWrapper}>
            <div className={styles.span}>
              {t('memoryBookPage.editProfileModal.avatar')}
            </div>
            <div className={styles.avatarSection}>
              <div className={styles.avatarInput}>
                <div className={styles.previewSmall}>
                  <img
                    src={
                      imageBytes ||
                      getURI(userStore.currentUser?.avatar?.url || '')
                    }
                    alt=''
                    className={styles.uploadedImage}
                  />
                  {avatar && (
                    <img
                      src={CloseIcon}
                      alt=''
                      className={styles.closeIcon}
                      onClick={removeAvatarSelection}
                    />
                  )}
                </div>
                <FileUploadButton onFileSelect={setAvatarState} />
              </div>
              {avatar && (
                <div className={styles.previewText}>
                  {t('memoryBookPage.editProfileModal.previewOnly')}
                </div>
              )}
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <div className={styles.span}>
              {t('memoryBookPage.editProfileModal.login')}
            </div>
            <div className={styles.input}>
              <Input
                value={login}
                placeholder={t('memoryBookPage.editProfileModal.login')}
                onChange={(event) => setLogin(event.target.value)}
              />
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <div className={styles.span}>
              {t('memoryBookPage.editProfileModal.nickName')}
            </div>
            <div className={styles.input}>
              <Input
                value={nickname}
                placeholder={t('memoryBookPage.editProfileModal.nickName')}
                onChange={(event) => setNickname(event.target.value)}
              />
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <div className={styles.span}>
              {t('memoryBookPage.editProfileModal.name')}
            </div>
            <div className={styles.input}>
              <Input
                value={name}
                placeholder={t('memoryBookPage.editProfileModal.name')}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <div className={styles.span}>
              {t('memoryBookPage.editProfileModal.surname')}
            </div>
            <div className={styles.input}>
              <Input
                value={surname}
                placeholder={t('memoryBookPage.editProfileModal.surname')}
                onChange={(event) => setSurname(event.target.value)}
              />
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <div className={styles.span}>
              {t('memoryBookPage.editProfileModal.city')}
            </div>
            <div className={styles.input}>
              <Input
                value={city ? city : ''}
                placeholder={t('memoryBookPage.editProfileModal.city')}
                onChange={(event) => setCity(event.target.value)}
              />
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <div className={styles.span}>
              {t('memoryBookPage.editProfileModal.email')}
            </div>
            <div className={styles.input}>
              <Input
                value={email}
                placeholder={t('memoryBookPage.editProfileModal.email')}
                onChange={(event) => onEmailChange(event.target.value)}
              />
              {!isValidEmail ? (
                <div className={styles.incorrectEmail}>
                  {t('memoryBookPage.editProfileModal.invalidEmail')}
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <div className={styles.span}>
              {t('memoryBookPage.editProfileModal.password')}
            </div>
            <div className={styles.passwordInput}>
              <Input.Password
                value={password}
                placeholder={t('memoryBookPage.editProfileModal.password')}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <div className={styles.span}>
              {t('memoryBookPage.editProfileModal.repeatPassword')}
            </div>
            <div className={styles.passwordInput}>
              <Input.Password
                value={confirmPassword}
                placeholder={t(
                  'memoryBookPage.editProfileModal.repeatPassword'
                )}
                onChange={(event) =>
                  onConfirmPasswordChange(event.target.value)
                }
              />
              {!isPasswordsMatch ? (
                <div className={styles.passwordsShouldMatch}>
                  {t('memoryBookPage.editProfileModal.passwordsShouldMatch')}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <button
            className={classNames(styles.actionButton, styles.active)}
            onClick={onSaveClick}
          >
            {t('memoryBookPage.editProfileModal.save')}
          </button>
          <button className={styles.actionButton} onClick={onCancelClick}>
            {t('memoryBookPage.editProfileModal.cancel')}
          </button>
        </div>
      </div>
    );
  }
);

export default EditProfileModal;
