import { ReactElement } from 'react';
import styles from './InfoBox.module.scss';
import { getURI } from '../../../../../api/helper';
import classNames from 'classnames';

type InfoBoxProps = {
    name: string;
    value: string | number;
    flagUri?: string;
    dopInfoBox?: string;
}

function InfoBox({name, value, flagUri, dopInfoBox}: InfoBoxProps): ReactElement {
    return (
        <div className={styles.infoBox}>
            <span className={styles.infoKey}>{name}</span>
            <div>
                {flagUri && <img src={getURI(flagUri)} />}
                <span className={flagUri ? classNames(styles.infoValue, styles.flagMargin) : styles.infoValue}>{value} {dopInfoBox}</span>
            </div>
        </div>
    );
}

export default InfoBox;
