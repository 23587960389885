import { ReactElement, useEffect, useState } from 'react';
import styles from './ScoreTable.module.scss';
import { GameProtocolDto, GameWithLogo } from '../../../../../types/Types';
import { getURI } from '../../../../../api/helper';
import classNames from 'classnames';

type PeriodsData = {
  period1: number;
  period2: number;
  period3: number;
  overtime: { [key: string]: number };
  shootouts: number;
  total: number;
};

type teamData = {
  khlId: string;
  inGameId: string;
  periods: PeriodsData;
  name: string;
  logo: string;
  score: string;
};

type ScoreTableProps = {
  protocol: GameProtocolDto;
  game: GameWithLogo;
};

function ScoreTable({ protocol, game }: ScoreTableProps): ReactElement {
  const [teams, setTeams] = useState<teamData[]>();
  const [isOvertime, setIsOvertime] = useState<boolean>(false);
  const [isShootouts, setIsShootouts] = useState<boolean>(false);
  const windowInnerWidth = document.documentElement.clientWidth;
  const isSmallMobile = windowInnerWidth < 360;

  useEffect(() => {
    const teamsData = [
      {
        khlId: protocol.GameSummary['@_teama'],
        inGameId: protocol.GameSummary['@_homeId'],
        periods: getPeriodsData(protocol.GameSummary['@_homeId']),
        name: game.attributes.team_1.data.attributes.name,
        logo: game.attributes.team_1.data.attributes.logo.data.attributes.url,
        score: protocol.GameSummary['@_score'],
      },
      {
        khlId: protocol.GameSummary['@_teamb'],
        inGameId: protocol.GameSummary['@_visitorId'],
        periods: getPeriodsData(protocol.GameSummary['@_visitorId']),
        name: game.attributes.team_2.data.attributes.name,
        logo: game.attributes.team_2.data.attributes.logo.data.attributes.url,
        score: protocol.GameSummary['@_score'].split('').reverse().join(''),
      },
    ];

    const overtime = !!teamsData?.find(
      (item) => Object.keys(item.periods.overtime).length
    );
    const shootouts = !!teamsData?.find((item) => item.periods.shootouts);

    setTeams(teamsData);
    setIsOvertime(overtime);
    setIsShootouts(shootouts);
  }, [game, protocol]);

  const getPeriodsData = (teamId: string) => {
    const goalsData = Array.isArray(protocol.GameSummary.Goals.Goal)
      ? protocol.GameSummary.Goals.Goal
      : [protocol.GameSummary.Goals.Goal];
    const teamGoals = goalsData.filter((item) => item['@_teamId'] === teamId);

    const result: PeriodsData = {
      period1: 0,
      period2: 0,
      period3: 0,
      overtime: {},
      shootouts: 0,
      total: 0,
    };

    for (let goal of teamGoals) {
      if (goal['@_per'] === '1') {
        result.period1++;
      }
      if (goal['@_per'] === '2') {
        result.period2++;
      }
      if (goal['@_per'] === '3') {
        result.period3++;
      }
      if (parseInt(goal['@_per']) >= 4) {
        if (protocol.GameSummary.Shootout) {
          result.shootouts++;
        } else {
          if (result.overtime[goal['@_per']]) {
            result.overtime[goal['@_per']]++;
          } else {
            result.overtime[goal['@_per']] = 1;
          }
        }
      }
      result.total++;
    }

    return result;
  };

  const getOvertimeValue = (overtime: { [key: string]: number }) => {
    const keysValues = Object.entries(overtime);

    if (keysValues.length) {
      const result = keysValues.find((pair) => pair[1] > 0);

      return result ? result[1] : keysValues[0][1];
    }

    return 0;
  };

  const getOvertimeKey = () => {
    if (teams) {
      const keysValues = [
        ...Object.entries(teams[0].periods.overtime),
        ...Object.entries(teams[1].periods.overtime),
      ];

      if (keysValues.length) {
        const result = keysValues.find((pair) => pair[1] > 0);

        return result
          ? parseInt(result[0]) - 3
          : parseInt(keysValues[0][0]) - 3;
      }
    }

    return '';
  };

  const renderRows = () => {
    if (teams) {
      return teams.map((team) => {
        return (
          <tr className={styles.tableRow} key={team.inGameId}>
            <td className={styles.status}>
              <div className={styles.team}>
                {isSmallMobile ? (
                  <></>
                ) : (
                  <img className={styles.logo} src={getURI(team.logo)} alt='' />
                )}
                <div className={styles.nameAndScore}>
                  <div className={styles.name}>{team.name}</div>
                </div>
              </div>
            </td>
            <td>{team.periods.period1}</td>
            <td>{team.periods.period2}</td>
            <td>{team.periods.period3}</td>
            {isOvertime && <td>{getOvertimeValue(team.periods.overtime)}</td>}
            {isShootouts && <td>{team.periods.shootouts}</td>}
            <td className={classNames(styles.lastItem, styles.total)}>
              {team.periods.total}
            </td>
          </tr>
        );
      });
    }

    return null;
  };

  return (
    <div>
      <table className={styles.table}>
        <tbody className={styles.tableBody}>
          <tr className={styles.tableHeader}>
            <th className={styles.status}>
              {game.attributes.is_current ? 'Идет' : 'Завершен'}
            </th>
            <th title={'Первый период'}>1-й</th>
            <th title={'Второй период'}>2-й</th>
            <th title={'Третий период'}>3-й</th>
            {isOvertime && (
              <th title={'Овертайм'}>{`ОТ${getOvertimeKey()}`}</th>
            )}
            {isShootouts && <th title={'Третий период'}>Б</th>}
            <th className={styles.lastItem} title={'Всего'}>
              И
            </th>
          </tr>
          {renderRows()}
        </tbody>
      </table>
    </div>
  );
}

export default ScoreTable;
