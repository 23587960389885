import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './ArticleMainItem.module.scss';
import { getURI } from '../../api/helper';
import ArticleLikesBlock from '../ArticleLikesBlock/ArticleLikesBlock';
import LikeIconActive from '../../assets/main-news-like-active.svg';
import { loadHightResolutionImage } from '../../helpers/helpers';
import Article from 'model/entity/loko/type/Article';
import Image from 'model/entity/loko/type/Image';

type ArticleMainItemProps = {
  item: Article;
  link: string;
  backgroundImage: Image | string;
};

function ArticleMainItem({
  item,
  link,
  backgroundImage,
}: ArticleMainItemProps): ReactElement {
  const [backgroundImageSrc, setBackgroundImageSrc] = useState<string>('');

  useEffect(() => {
    if (backgroundImage) {
      if (typeof backgroundImage === 'string') {
        setBackgroundImageSrc(backgroundImage);
      } else {
        const lowResSrc = backgroundImage.attributes.formats.small?.url || '';

        setBackgroundImageSrc(lowResSrc);
        loadHightResolutionImage(
          backgroundImage.attributes.url,
          setBackgroundImageSrc
        );
      }
    }
  }, [backgroundImage]);

  return (
    <div className={styles.transitionImg}>
      <Link to={link}>
        <div
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 10%, rgba(0, 0, 0, 0.8) 100%), 
        url(${getURI(backgroundImageSrc)})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
          className={styles.mainContentItem}
        ></div>

        <div className={styles.mainContentItemBody}>
          <div className={styles.mainContentItemBodyWrapper}>
            <div className={styles.content}>
              <div className={styles.titleWrapper}>
                <h2 className={styles.title}>{item.attributes.title}</h2>
              </div>
              <div
                className={styles.annotation}
                dangerouslySetInnerHTML={{
                  __html: item.attributes.annotation,
                }}
              />
            </div>
            <div className={styles.likes}>
              <ArticleLikesBlock
                likeIconActive={LikeIconActive}
                width={24}
                height={24}
                article={item}
                isMainNews={true}
              />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
export default ArticleMainItem;
