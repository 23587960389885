import IResponse from 'types/base/IResponse';
import {
  ArenaCategoryData,
  ArenaSectorData,
  Background,
  BackgroundsData,
  BannerData,
  ExecutiveType,
  ForumPostToSend,
  PlayerDto,
  Season,
  SubscriptionData,
  SubscriptionPrivilegesPageDto,
  SubscriptionTypeData,
  YoutubeRequestOptions,
} from '../types/Types';
import {
  get,
  post,
  getCategoryArticlesFilter,
  getDateArticlesFilter,
  getDateByStrapiFormating,
  getFilterIsLoko,
  getLokoIsAwayFilter,
  getLokoIsHomeFilter,
  getSearchArticlesFilter,
  getShop,
  getAuthorized,
  put,
  getAll,
  del,
} from './helper';
import { getTwoDigitNumber } from 'helpers/helpers';

export async function getMenu() {
  return get('/api/main-menu-categories?populate=*');
}

// TODO: doesn't look to be used. remove?
export async function getSubMenu() {
  return get('');
}

export async function getPartners() {
  return get('/api/partners?&pagination[limit]=-1&populate=*');
}

export async function getAdvertisingWidgets() {
  return get(
    '/api/advertising-widgets?populate[game][populate][team_1][populate][logo]=*&populate[game][populate][team_2][populate][logo]=*&populate[preview]=*&populate[main_image]=*'
  );
}

export async function getAwayMatchesData() {
  return get('/api/away-match?populate[slider][populate]=*');
}

export async function getFonbetPartnerHeader() {
  return get('/api/fonbet-partner-header');
}

export async function getKhlPartnerHeader() {
  return get('/api/khl-partner-header');
}

export async function getMhlPartnerHeader() {
  return get('/api/mhl-partner-header');
}

// TODO: unused (used in FooterLegacy)
export async function getSocialLinksHeader() {
  return get('/api/social-header');
}

export async function getStaticPageById(id: string) {
  return get(
    `/api/static-pages/${id}?populate[add_ons][populate]=*&populate[slider][populate]=*`
  );
}

// TODO: unused (used in FooterLegacy)
export async function getSocialLinks() {
  return get('/api/social-links?populate=*');
}

// TODO: used in ArticlesBlock that is never used
export async function getLastArticles() {
  return get(
    '/api/articles?populate=*&filters[tops][$eq]=false&sort[0]=publishedAt:desc&pagination[page]=0&pagination[pageSize]=6'
  );
}
export async function getArticleById(id: string) {
  return get(`/api/articles/${id}?populate=*`);
}

export async function getTopPartners() {
  return get('/api/top-partners?populate=*');
}

// TODO: used in OfficialTopPartner that doesn't look to be in use
export async function getOfficialTopPartners() {
  return get('/api/top-official-partners?populate=*');
}

// TODO: seems to be used in GameWidget that is not used
export async function getGameWithLogoById(id: number) {
  return get(
    `/api/games/${id}?populate[team_1][populate][0]=logo&populate[team_2][populate][0]=logo&populate[continuation]=*`
  );
}

export async function getGameById(id: number) {
  return get(
    `/api/games/${id}?populate[team_1][populate][0]=logo&populate[team_2][populate][0]=logo&populate[article][populate][0]=*&populate[continuation]=*&populate[highlights]=*`
  );
}

export async function getGamesByTeams(
  team_1: string,
  team_2: string,
  season: string
) {
  return get(
    `/api/games?populate[team_1][populate][0]=logo&populate[team_2][populate][0]=logo&populate[is_current]=*&populate[article]=*&filters[$or][0][$and][0][team_1][code][$eq]=${team_1}&filters[$or][0][$and][1][team_2][code][$eq]=${team_2}&filters[$or][1][$and][0][team_1][code][$eq]=${team_2}&filters[$or][1][$and][1][team_2][code][$eq]=${team_1}&filters[$and][2][season][code][$eq]=${season}`
  );
}

export async function getLastGame(lokomotivId: number) {
  const teamIsLoko = getFilterIsLoko(0, true, lokomotivId, []);
  const populate =
    'populate[team_1][populate][0]=logo&populate[team_2][populate][0]=logo&populate[continuation]=*&populate[article]=*&populate[is_current]=*';
  const gameIsPlayed =
    '&filters[$and][1][$or][0][score_1][$notNull]=true&filters[$and][1][$or][1][score_2][$notNull]=true';
  const sort = `&sort[0]=date:desc`;
  const pagination = `&pagination[page]=0&pagination[pageSize]=2`;

  return get(
    `/api/games?${populate}${teamIsLoko}${gameIsPlayed}${sort}${pagination}`
  );
}

export async function getNextGame(lokomotivId: number) {
  const teamIsLoko = getFilterIsLoko(0, true, lokomotivId, []);
  const populate =
    'populate[team_1][populate][0]=logo&populate[team_2][populate][0]=logo&populate[continuation]=*&populate[article]=*&populate[is_current]=*';
  const gameIsNotPlayed =
    '&filters[score_1][$null]=true&filters[score_2][$null]=true';
  const sort = `&sort[0]=date:asc`;
  const pagination = `&pagination[page]=0&pagination[pageSize]=2`;

  return get(
    `/api/games?${populate}${teamIsLoko}${gameIsNotPlayed}${sort}${pagination}`
  );
}

export async function getGamesInSeason(
  code: string,
  isHome: boolean | undefined,
  lokomotivId: number,
  lokomotivTeamsIds: number[]
) {
  let filter = '';
  if (isHome === true) {
    filter = getLokoIsHomeFilter(false, lokomotivId, lokomotivTeamsIds);
  } else if (isHome === false) {
    filter = getLokoIsAwayFilter(false, lokomotivId, lokomotivTeamsIds);
  } else {
    filter = getFilterIsLoko(0, false, lokomotivId, lokomotivTeamsIds);
  }
  return get(
    `/api/games/?populate[team_1][populate][0]=logo&populate[team_2][populate][0]=logo&populate[continuation]=*&populate[season]=*&populate[article][fields][0]=id&populate[highlights]=*&filters[season][code][$eq]=${code}${filter}&sort[0]=date:asc&pagination[limit]=-1`
  );
}

// TODO: unused
export async function getGamesInCurrentSeason(code: string) {
  return get(
    `/api/games/?populate[team_1][populate][0]=logo&populate[team_2][populate][0]=logo&populate[continuation]=*&populate[season]=*&populate[article][fields][0]=id&filters[season][code][$eq]=${code}&sort[0]=date:asc&pagination[limit]=-1`
  );
}

export async function getSeasonByCode(code: string) {
  return get(`/api/seasons?filters[code][$eq]=${code}`);
}

export async function getSeasonByPostfix(postfix: string) {
  return get(
    `/api/seasons?pagination[limit]=-1&filters[code][$contains]=${postfix}&sort[0]=years_interval:desc`
  );
}

export async function getSeasonsByTeamId(team: string) {
  return getAll<Season>(
    `/api/seasons?populate[season]=*&filters[team][$eq]=${team}&sort[0]=year:desc&sort[1]=code:asc`,
    (paginationStart) =>
      `/api/seasons?populate[season]=*&filters[team][$eq]=${team}&sort[0]=year:desc&sort[1]=code:asc&pagination[limit]=25&pagination[start]=${paginationStart}`
  );
}

// TODO: unused
export async function getSeasonsForLunAndL76() {
  return get(
    `/api/seasons?populate[season]=*&filters[$or][0][team][$eq]=LUN&filters[$or][0][team][$eq]=L76&sort[0]=year:desc&sort[1]=code:asc`
  );
}

export async function getDefaultSeason() {
  const response = await get('/api/default-season');

  return response;
}

// TODO: unused
export async function getCalendarSubMenu() {
  return get(
    `/api/main-menu-categories?populate=*&filters[name][$eq]=calendar`
  );
}

export async function getArticlesUsingFilters(
  searchParam: string | null,
  categoryParam: string | null,
  yearParam: string | null,
  monthParam: string | null,
  pageNumber: number = 0,
  pageSize: number = 15
) {
  return get(
    `/api/articles?populate=*${getSearchArticlesFilter(
      searchParam
    )}${getCategoryArticlesFilter(categoryParam)}${getDateArticlesFilter(
      yearParam,
      monthParam
    )}&sort[0]=date:desc&pagination[page]=${pageNumber}&pagination[pageSize]=${pageSize}&filters[$or][2][team_filter][code][$null]=true&filters[$or][3][team_filter][code][$eq]=LOK`
  );
}

export async function getTopArticle() {
  return get(
    `/api/articles?populate[image][populate]=*&pagination[page]=1&pagination[pageSize]=15&sort[0]=date:desc&filters[$and][0][tops][$eq]=true&filters[$and][1][tops_order][$eq]=1&filters[$or][2][team_filter][code][$null]=true&filters[$or][3][team_filter][code][$eq]=LOK`
  );
}

// TODO: used on ArchivePage that is commented out
export async function getAllArticlesCategories() {
  return get('/api/article-categories');
}

export async function getArticleBottomBanner() {
  const response = await get(
    '/api/article-footer-banner?populate[image][populate]=*'
  );

  return response?.data;
}

export async function getRosterBySeasonCode(seasonCode: string) {
  return get(
    `/api/team-rosters?populate[player][populate][0]=position&populate[season]=*&populate[team]=*&populate[assignment]=*&filters[season][name][$eq]=${seasonCode}&filters[active][$eq]=true&pagination[page]=0&pagination[pageSize]=100`
  );
}

// TODO: seems to be used in src\components\RosterPage\RosterPage.tsx that is replaced by src\components\pages\RosterPage\RosterPage.tsx now
export async function getRostersSeasonsByTeamCode(teamCode: string) {
  return get(
    `/api/roster-seasons?populate=*&filters[name][$containsi]=${teamCode}&filters[display][$eq]=true&sort[0]=years_interval:desc`
  );
}

// TODO: seems to be used in src\components\RosterPage\RosterPage.tsx that is replaced by src\components\pages\RosterPage\RosterPage.tsx now
export async function getRostersSeasonsForLunAndL76() {
  return get(
    `/api/roster-seasons?populate=*&filters[$or][0][name][$containsi]=LUN&filters[$or][0][name][$containsi]=L76&filters[display][$eq]=true&sort[0]=years_interval:desc`
  );
}

export async function getAllRosterSeasons() {
  return get(
    `/api/roster-seasons?populate=*&sort[1]=name:desc&pagination[limit]=-1`
  );
}

export async function getPlayerById(playerId: number) {
  return get(
    `/api/players/${playerId}?populate[citizenship][populate][1]=flag&populate[rosters][populate]=season&populate[position]=*&populate[grip]=*&populate[photo]=*&populate[bg_photo]=*&populate[main_bg_photo]=*&populate[contract_type]=*&populate[statistic][populate]=*`
  );
}

export async function getPlayerByIdLeague(idLeagueWeb?: number | null) {
  if (!idLeagueWeb) {
    return undefined;
  }
  return get(
    `/api/players?populate[rosters][populate]=season&populate[position]=*&populate[grip]=*&populate[photo]=*&populate[bg_photo]=*&filters[id_league_web][$eq]=${idLeagueWeb}`
  );
}

export async function getExecutivesByType(
  type: ExecutiveType,
  season: string,
  isNeedTeamFilter?: boolean
) {
  let commonUrl = `/api/executives?filters[type][$eq]=${type}&populate[photo]=*&populate[bg_photo]=*&populate[seasons]=*`;
  if (season) {
    const seasonFilter = `&filters[seasons][code][$contains]=${season}`;
    commonUrl += seasonFilter;
  }
  if (isNeedTeamFilter) {
    const teamFilter = `&filters[team_filter][code][$eq]=LOK`;
    commonUrl += teamFilter;
  }
  return get(commonUrl);
}

export async function getExecutiveRosters(type: ExecutiveType, season: string) {
  return get(
    `/api/executive-rosters?populate[executive][populate][1]=photo&populate[executive][populate][2]=bg_photo&populate[season]=*&filters[season][name][$eq]=${season}&filters[executive][type][$eq]=${type}`
  );
}

export async function getExecutiveById(executiveId: number) {
  return get(
    `/api/executives/${executiveId}?populate[photo]=*&populate[bg_photo]=*&populate[statistics][populate]=*&populate[citizenship][populate][1]=flag&populate[executive_rosters][populate][1]=season&populate[team_filter][populate]=*`
  );
}

export async function getStandings(season: string | null) {
  return get(`/api/statistic?${season ? 'season=' + season : ''}`);
}

export async function getStandingsLegacy(season: string | null) {
  return get(`/api/statistic-legacy?${season ? 'season=' + season : ''}`);
}

export async function getPlayoffStandings(season: string | null) {
  return get(`/api/playoff-statistic?${season ? 'season=' + season : ''}`);
}

// TODO: usused
export async function getMatchSeasons() {
  return get(
    '/api/seasons?pagination[limit]=-1&sort[0]=years_interval:desc&sort[1]=name:desc'
  );
}

// TODO: Unused
export async function getSubMenuItems(parentItem: Number) {
  const initialResponse = await get(
    `/api/main-menu-categories/${parentItem}?populate=*`
  );
  return initialResponse?.data?.attributes?.main_menu_item?.data;
}

export async function getPlayerSeasonStats(playerId: number, season: string) {
  return get(`/api/player-statistic?id=${playerId}&season=${season}`);
}

export async function getSeasonStats(season: string) {
  return get(`/api/player-statistic?season=${season}`);
}

// TODO: unused
export async function getPlayerAlbum(
  searchString: string,
  pageNumber: number = 0
) {
  return get(
    `/api/photo-categories?filters[$or][0][caption][$contains]=${searchString}&filters[display][$eq]=true&sort[0]=id:desc&pagination[page]=${pageNumber}&pagination[pageSize]=15`
  );
}

export async function getAlbum(
  year: string | null,
  search: string | null,
  pageNumber: number = 0
) {
  // TODO: Add logic to filter by search string
  const seasonFilter = year ? `&filters[season]=${year}` : '';
  const teamFilter =
    '&filters[$and][0][$or][0][team][code][$null]=true&filters[$and][0][$or][1][team][code][$eq]=MEN';
  return get(
    `/api/photo-categories?populate=*${seasonFilter}${teamFilter}&filters[$or][1][wallpaper][$eq]=false&filters[$or][2][wallpaper][$null]=true&filters[display][$eq]=true&sort[0]=id:desc&pagination[page]=${pageNumber}&pagination[pageSize]=15`
  );
}

export async function getAlbumByFilters(
  category: string | null,
  month: number | null,
  year: string | null,
  pageCount: number = 0
) {
  const teamFilter = category
    ? `&filters[$and][0][team][code][$eq]=${category}`
    : '&filters[$and][0][$or][0][team][code][$null]=true&filters[$and][0][$or][1][team][code][$eq]=MEN';
  let monthYearFilter = '';

  if (year) {
    if (month) {
      const monthString = month < 10 ? `0${month}` : month;
      const nextMonthString =
        month < 12 ? getTwoDigitNumber((month + 1).toString()) : '01';
      const ltYearString = month < 12 ? year : (Number(year) + 1).toString();
      monthYearFilter = `&filters[date][$gte]=${year}-${monthString}-01T00:00:00.000Z&filters[date][$lt]=${ltYearString}-${nextMonthString}-01T00:00:00.000Z`;
    } else {
      const ltYearString = (Number(year) + 1).toString();
      monthYearFilter = `&filters[date][$gte]=${year}-01-01T00:00:00.000Z&filters[date][$lt]=${ltYearString}-01-01T00:00:00.000Z`;
    }
  }

  return get(
    `/api/photo-categories?populate=*${monthYearFilter}${teamFilter}&filters[$and][1][$or][1][wallpaper][$eq]=false&filters[$and][1][$or][2][wallpaper][$null]=true&filters[display][$eq]=true&sort[0]=id:desc&pagination[pageSize]=${pageCount}`
  );
}

export async function getAlbumById(albumId: string) {
  return get(`/api/photo-categories/${albumId}?populate=*`);
}

// TODO: used on downloads page. Is it used?
export async function getDownloadsAlbums(pageNumber: number = 0) {
  return get(
    `/api/photo-categories?populate=*&filters[wallpaper][$eq]=true&filters[display][$eq]=true&sort[0]=id:desc&pagination[page]=${pageNumber}&pagination[pageSize]=15`
  );
}

// TODO: unused
export async function getPlayersFromSeason(season: string) {
  return get(
    `/api/players?filters[rosters][season][name][$contains]=${season}&pagination[limit]=-1`
  );
}

export async function getPlayersFromSeasonWithPaging(season: string) {
  return getAll<PlayerDto>(
    `/api/players?filters[rosters][season][name][$contains]=${season}&populate[rosters][populate]=*&populate[position][populate]=*&populate[grip][populate]=*&populate[photo][populate]=*&populate[bg_photo][populate]=*&populate[contract_type][populate]=*&populate[citizenship][populate]=*&populate[main_bg_photo][populate]=*&populate[localizations][populate]=*`,
    (paginationStart) =>
      `/api/players?pagination[limit]=25&pagination[start]=${paginationStart}&filters[rosters][season][name][$contains]=${season}&populate[rosters][populate]=*&populate[position][populate]=*&populate[grip][populate]=*&populate[photo][populate]=*&populate[bg_photo][populate]=*&populate[contract_type][populate]=*&populate[citizenship][populate]=*&populate[main_bg_photo][populate]=*&populate[localizations][populate]=*`
  );
}

export async function listPlayers() {
  return getAll<PlayerDto>(
    '/api/players?populate[rosters][populate]=*',
    (paginationStart) =>
      `/api/players?pagination[limit]=25&pagination[start]=${paginationStart}&populate[rosters][populate]=*`
  );
}

export async function getDefaultImages() {
  return get(`/api/default-images?populate=*`);
}

export async function getDefaultPlayerImage(imageName: string) {
  return get(
    `/api/default-images?filters[description][$eq]=${imageName}&populate=*`
  );
}

export async function getPhotos(searchString: string, limit: number) {
  return get(
    `/api/upload/files?` +
      `filters[$or][0][caption][$contains]=${searchString}` +
      `&filters[$or][1][alternativeText][$contains]=${searchString}` +
      `&filters[$and][2][mime][$contains]=image/` +
      `&sort[0]=createdAt:desc` +
      `&limit=${limit}`
  );
}

export async function getVideos(searchString: string, limit: number) {
  return get(
    `/api/upload/files?` +
      `filters[$or][0][caption][$contains]=${searchString}` +
      `&filters[$or][1][alternativeText][$contains]=${searchString}` +
      `&filters[$and][2][mime][$contains]=video/` +
      `&sort[0]=createdAt:desc` +
      `&limit=${limit}`
  );
}

export async function getYouTubeVideosByTag(
  tag: string,
  options?: YoutubeRequestOptions
) {
  const params = [];

  if (options) {
    for (const [key, value] of Object.entries(options)) {
      params.push(`${key}=${value}`);
    }
  }

  let requestParams = params.length
    ? `?tag=${tag}&${params.join('&')}`
    : `?tag=${tag}`;

  return get(`/api/youtube-videos/searchByTag${requestParams}`);
}

export async function getPaginationPhotos(searchString: string, limit: number) {
  return get(
    `/api/upload/files?` +
      `filters[$or][0][caption][$contains]=${searchString}` +
      `&filters[$or][1][alternativeText][$contains]=${searchString}` +
      `&filters[$and][2][mime][$contains]=image/` +
      `&sort[0]=createdAt:desc` +
      `&limit=${limit}`
  );
}

export async function getPlayerArticles(
  searchString: string,
  limit: number,
  skip: number
) {
  return get(
    `/api/articles?` +
      `filters[$or][0][title][$contains]=${searchString}` +
      `&filters[$or][1][annotation][$contains]=${searchString}` +
      `&filters[$or][2][tags][tag][$contains]=${searchString}` +
      `&filters[publishedAt][$notNull]=true` +
      `&sort[0]=date:desc&sort[1]=id:desc&pagination[limit]=${limit}&pagination[start]=${skip}&populate=*`
  );
}

export async function getForumCategories() {
  return get(`/api/forum-categories`);
}

export async function getForumPostsPage(
  category: string,
  pageSize: string,
  skip: string
) {
  return await get(
    `/api/forums?filters[category][id][$eq]=${category}` +
      `&pagination[limit]=${pageSize}&pagination[start]=${skip}` +
      `&sort[0]=id:desc`
  );
}

export async function getForumPost(id: string) {
  return await get(`/api/forums/${id}`);
}

export async function getForumConditions() {
  return get(`/api/forum-condition`);
}

export async function getProducts() {
  return getShop(`/api/products/grouped/promo`);
}

export async function login(data: any) {
  return post('/api/auth/local', data, null);
}

export async function postForumMessage(
  data: ForumPostToSend,
  token: string | undefined
) {
  return post('/api/forums', data, token);
}

export async function editForumMessage(
  id: number,
  data: ForumPostToSend,
  token: string | undefined
) {
  data.id = id;
  return put(`/api/forums/${id}`, data, token);
}

export async function whoAmI(token: string | null) {
  return getAuthorized('/api/users/me?populate=*', token);
}

export async function uploadImage(data: FormData, token: string | null) {
  return post('/api/upload', data, token);
}

// export async function updateProfile(data: FormData, token: string|null) {
//     return post('/api/users/updateself', data, token);
// }

export async function updateProfile(data: any, token: any) {
  return put(`/api/users/me`, data, token);
}

export async function registration(data: any) {
  return post('/api/auth/local/register', data);
}

export async function emailConfirmation(confirmationCode: string) {
  return get(`/api/users/email-confirmation?confirmation=${confirmationCode}`);
}

export async function forgotPassword(data: any) {
  return post('/api/auth/forgot-password', data);
}

export async function recoveryPassword(data: any) {
  return post('/api/auth/reset-password', data);
}

export async function karmaVote(
  id: number,
  value: number,
  token: string | undefined
) {
  return put(`/api/forums/karma/${id}?value=${value}`, {}, token);
}

export async function getSmiles() {
  return get('/api/smiles?pagination[limit]=-1&populate=*');
}

export async function getDocuments() {
  return get(
    '/api/documents?populate[name]=*&populate[document]=*&populate[document_link]=*&populate[display_order]=*&populate[hide_document]=*'
  );
}

export async function getBanners(): Promise<BannerData> {
  return getShop('/api/banners?filters[type][type][$eq]=MainPage&populate=*');
}

export async function likeArticle(id: number) {
  return put(`/api/articles/${id}/like`, {});
}

export async function dislikeArticle(id: number) {
  return put(`/api/articles/${id}/dislike`, {});
}

export async function getAllPageBackgrounds(): Promise<BackgroundsData> {
  return getAll<Background>(
    '/api/page-backgrounds?populate=*',
    (paginationStart) =>
      `/api/page-backgrounds?pagination[limit]=25&pagination[start]=${paginationStart}&populate=*`
  );
}

export async function getSideMenuPartners() {
  return get(
    '/api/side-menu-partners?populate[partner][populate][1]=logo&populate[partner][populate]=partner_category'
  );
}

export async function getSideMenuWidgets() {
  return get('/api/side-menu-widgets?populate=*');
}

export async function getProgramAppeals() {
  return get('/api/programs-appeal');
}

export async function getSponsors() {
  return get('/api/sponsors?populate[logo]=*');
}

export async function getGameProtocol(gameId: number | string) {
  return get(`/api/games/protocol?gameId=${gameId}`);
}

export async function getAchievements() {
  return get(
    '/api/achievements?filters[$or][0][team_filter][code][$eq]=LOK&filters[$or][1][team_filter][code][$null]=true&populate=*'
  );
}

export async function getGameHighlights(gameId: number | string) {
  return get(`/api/games/highlights?gameId=${gameId}`);
}

export async function getGameBroadcast(gameId: number | string) {
  return get(`/api/games/broadcast?gameId=${gameId}`);
}

export async function getInfrastructureInformation() {
  return get(
    '/api/complex-pages?populate[page][populate][objects][populate]=images&populate[page][populate][images][populate]=*'
  );
}

export async function getSubscriptionPrivilegesData(): Promise<SubscriptionPrivilegesPageDto> {
  return get('/api/subscription-privilege?populate=*');
}

export async function getFoulsList() {
  return get('/api/fouls?pagination[limit]=-1');
}

export async function getLokomotivTeams() {
  return get('/api/lokomotiv-team?populate=*');
}

export async function getYouTubeVideoList(options?: YoutubeRequestOptions) {
  const params = [];

  if (options) {
    for (const [key, value] of Object.entries(options)) {
      params.push(`${key}=${value}`);
    }
  }

  let requestParams = params.length ? `?${params.join('&')}` : '';

  return get(`/api/youtube-videos/videoList${requestParams}`);
}

export async function getYouTubeVideos(ids: string[]) {
  const params = ids.map((id) => `ids[]=${id}`);
  let requestParams = params.length ? `?${params.join('&')}` : '';

  return get(`/api/youtube-videos/videos${requestParams}`);
}

export async function postEmail(data: any) {
  return post('/api/email-subscribers', data);
}

export async function deleteEmail(email: string) {
  return del(`/api/email-subscribers/unsubscribe/${email}`);
}

export async function getMemoryBookRules() {
  return get('/api/memory-book-rule?populate=*');
}

export async function sendAwayGameForm(data: any) {
  return post('/api/email-away-match/send-email', data);
}

export async function getArenaSectors() {
  return get(
    '/api/arena-sectors?populate[subscription]=*&pagination[limit]=-1'
  );
}
