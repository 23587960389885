import { ScheduleDay } from 'types/Types';
import styles from './DayMobile.module.scss';
import { DayType } from './DayType';
import classNames from 'classnames';
import Column from 'components/core/layout/Column';
import { DAYS_SHORT_OF_WEEK } from 'constants/constants';
import { getURI } from 'api/helper';
import { getScore } from 'helpers/helpers';
import Row from 'components/core/layout/Row';
import ShowOn from 'components/core/adaptivity/ShowOn';
import HoverGame from '../HoverGame/HoverGame';
import { useState } from 'react';
import { Modal } from 'antd';

type DayMobileProps = {
  slickDay: ScheduleDay;
  isCalendarPage: boolean;
  type: DayType;
  isHover?: boolean;
};

const DayMobile = ({
  slickDay,
  isCalendarPage,
  type,
  isHover,
}: DayMobileProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    if (type === 'isHome' || type === 'isAway') setIsOpen(true);
  };

  const handleClose = () => {
    setTimeout(() => {
      // Работает, не трогай
      // Потомки, вам загадка от Жака Фреско:
      // Установил isOpen в false, а он true
      // Вопрос: почему?
      setIsOpen(false);
    }, 100);
  };

  const getImageLokomotiv = () =>
    getURI(
      slickDay.game?.attributes.team_1?.data?.attributes.logo?.data?.attributes
        .url || ''
    );

  const getImageOpponent = () =>
    getURI(
      slickDay.game?.attributes.team_2?.data?.attributes.logo?.data?.attributes
        .url || ''
    );

  const renderScore = (isCompact: boolean) => {
    if (slickDay.game && (type === 'isHome' || type === 'isAway'))
      return (
        <>
          <span
            className={classNames(styles.scoreText, {
              [styles.scoreTextCompact]: isCompact,
              [styles.scoreTextWide]: !isCompact,
              [styles.scoreTextRed]:
                type === 'isHome' &&
                slickDay?.game?.attributes?.score_1 !== null &&
                slickDay?.game?.attributes?.score_2 !== null,
              [styles.scoreTextBlue]:
                type === 'isAway' &&
                slickDay?.game?.attributes?.score_1 !== null &&
                slickDay?.game?.attributes?.score_2 !== null,
            })}
          >
            {getScore(slickDay.game.attributes.score_1)}:
            {getScore(slickDay.game.attributes.score_2)}
          </span>
          <Row>
            <div>
              <img
                src={getImageLokomotiv()}
                alt='lokomotiv logo'
                className={classNames(styles.teamLogo, {
                  [styles.teamLogoCompact]: isCompact,
                  [styles.teamLogoWide]: !isCompact,
                })}
              />
            </div>
            <div>
              <img
                src={getImageOpponent()}
                alt='opponent logo'
                className={classNames(styles.teamLogo, {
                  [styles.teamLogoCompact]: isCompact,
                  [styles.teamLogoWide]: !isCompact,
                })}
              />
            </div>
          </Row>
        </>
      );
    return null;
  };

  const render = (isCompact: boolean) => {
    const wrapperClass =
      type === 'isHome'
        ? styles.dayWrapperHome
        : type === 'isAway'
        ? styles.dayWrapperAway
        : undefined;

    return (
      <>
        <div className={classNames(styles.dayWrapper, wrapperClass)}>
          <Column
            alignItems='center'
            justifyContent='start'
            width='100%'
            height='100%'
          >
            <span
              className={classNames(styles.dateText, {
                [styles.dateTextCompact]: isCompact,
                [styles.dateTextWide]: !isCompact,
                [styles.dateTextRed]: type === 'isHome',
                [styles.dateTextBlue]: type === 'isAway',
                [styles.dateTextNotCurrent]: type === 'notCurrent',
              })}
            >
              {slickDay.day.getDate()}
            </span>
            <span
              className={classNames(styles.dayNameText, {
                [styles.dayNameTextCompact]: isCompact,
                [styles.dayNameTextWide]: !isCompact,
                [styles.dayNameTextRed]: type === 'isHome',
                [styles.dayNameTextBlue]: type === 'isAway',
                [styles.dayNameTextNotCurrent]: type === 'notCurrent',
              })}
            >
              {DAYS_SHORT_OF_WEEK.get(slickDay.day.getDay()).toUpperCase()}
            </span>
            {renderScore(isCompact)}
          </Column>
        </div>
      </>
    );
  };

  return (
    <div className={styles.container} onClick={handleOpen}>
      <ShowOn largeTablet smallTablet>
        {render(false)}
      </ShowOn>
      <ShowOn largeMobile smallMobile>
        {render(true)}
      </ShowOn>
      <Modal
        open={isOpen}
        onCancel={handleClose}
        footer={null}
        centered
        className={styles.modal}
        width={350}
        destroyOnClose={true}
      >
        {slickDay.game && (
          <HoverGame game={slickDay.game} isHome={type === 'isHome'} />
        )}
      </Modal>
    </div>
  );
};

export default DayMobile;
