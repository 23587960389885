import { useEffect, useState } from 'react';
import { ExecutiveDto, ExecutiveRosterDto, ExecutiveType } from 'types/Types';
import InfoWrapper from '../../Wrappers/InfoWrapper/InfoWrapper';
import ImageBgPageWrapper from '../../common/ImageBgPageWrapper/ImageBgPageWrapper';
import Container from '../../common/Container/Container';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import { observer } from 'mobx-react-lite';
import ExecutiveTiles from '../../ExecutiveTiles/ExecutiveTiles';
import styles from './ExecutivesPage.module.scss';
import {
  EXECUTIVESNAMES,
  EXECUTIVESNAMES_CATASTROPHE,
  EXECUTIVESURLS,
  SEASON_BOUND_EXECUTIVES,
} from '../../../constants/constants';
import {
  getExecutiveRosters,
  getExecutivesByType,
  getSeasonsByTeamId,
} from '../../../api/api';
import { doSortByDisplayOrder } from 'helpers/helpers';
import { useMatch } from 'react-router-dom';
import { useStore } from 'store/store';
import Spinner from 'components/Spinner/Spinner';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import { useTranslation } from 'react-i18next';
import Select, { SelectOption } from 'components/common/Select/Select';

type ExecutivesPageProps = {
  type: ExecutiveType;
  isCatastrophe: boolean;
};

const ExecutivesPage = observer(
  ({ type, isCatastrophe }: ExecutivesPageProps) => {
    const { t } = useTranslation();
    const { currentSeasonStore } = useStore();
    const [executives, setExecutives] = useState<Array<ExecutiveDto>>([]);
    const [seasonFilter, setSeasonFilter] = useState<number>();
    const [season, setSeason] = useState<string>('');
    // always default there
    const [seasonPostfix, setSeasonPostfix] = useState<string>('MEN');
    const matchCoach = useMatch('/catastrophe/coach/');
    const matchStaff = useMatch('/catastrophe/staff/');
    const [teamCode, setTeamCode] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [seasons, setSeasons] = useState<SelectOption[]>([]);

    useEffect(() => {
      if (matchCoach || matchStaff) {
        setTeamCode('catastrophe');
      }
    }, [currentSeasonStore.currentSeason]);

    useEffect(() => {
      const seasonCode =
        (teamCode
          ? currentSeasonStore.getSeasonByCode(teamCode)
          : currentSeasonStore.getSeasonByCode(seasonPostfix)) ?? '';
      const isPrip = seasonCode.includes('prip');
      if (isPrip && seasons) {
        setSeason(((seasons[0] as any)?.key ?? '0000') + 'MEN');
      } else {
        setSeason(seasonCode.slice(0, 7));
      }
    }, [teamCode, currentSeasonStore.currentSeason, seasons, seasonPostfix]);

    useEffect(() => {
      (async () => {
        const seasonList = await getSeasonsByTeamId(teamCode || 'men');
        const seasonsFilteredData = seasonList.data
          .filter(
            (element) => element.attributes.code.length === 7 // yyyyMEN
          )
          .map((elem) => {
            const year = elem.attributes.year;
            const yearValue = Number(year.toString().slice(2)) + '';
            const yearDisplayValue = `СЕЗОН ${year - 1}/${yearValue}`;
            return {
              key: year,
              value: yearValue,
              displayValue: yearDisplayValue,
            };
          });
        setSeasons(seasonsFilteredData);
      })();
    }, [teamCode]);

    useEffect(() => {
      if (season) {
        setSeasonFilter(Number(season?.toString().slice(2, 4)));
        setSeasonPostfix(season?.toString().slice(4, 7));
      }
    }, [season]);

    useEffect(() => {
      const getData = async () => {
        try {
          let data: ExecutiveDto[] = [];

          if (SEASON_BOUND_EXECUTIVES.includes(type) && !isCatastrophe) {
            const response = await getExecutiveRosters(
              type,
              `20${seasonFilter + seasonPostfix}`
            );

            const mapData = response.data?.map((item: ExecutiveRosterDto) => {
              const executive = item.attributes.executive.data;

              executive.attributes.position = item.attributes.position;

              return executive;
            });

            data = mapData;
          } else {
            const isNeedTeamFilter = [
              ExecutiveType.EXECUTIVE,
              ExecutiveType.MANAGER,
              ExecutiveType.PRESS,
            ].some((executiveType: ExecutiveType) => executiveType === type);

            const response = await getExecutivesByType(
              type,
              '',
              isNeedTeamFilter
            );

            data = response.data;
          }

          const filteredData = isCatastrophe
            ? data?.filter(
                (item: ExecutiveDto) => item.attributes.is_catastrophe
              )
            : data?.filter(
                (item: ExecutiveDto) => !item.attributes.is_catastrophe
              );
          setExecutives(doSortByDisplayOrder(filteredData));
        } catch (e: any) {
          setIsError(true);
          console.error(e?.response?.data?.error || e?.message);
        } finally {
          setIsLoading(false);
        }
      };
      getData();
    }, [seasonFilter, seasonPostfix]);

    function changeFilter(value: string) {
      setSeasonFilter(Number(value) || 0);
    }

    return (
      <ImageBgPageWrapper>
        <Container paddingTop='50px' paddingBottom='0px'>
          <div className={styles.breadcrumbsWrapper}>
            <BreadcrumbsWrapper
              breadcrumbs={[
                {
                  name: isCatastrophe
                    ? EXECUTIVESNAMES_CATASTROPHE.get(type)
                    : EXECUTIVESNAMES.get(type),
                  url: isCatastrophe
                    ? `/catastrophe/${type}`
                    : `${EXECUTIVESURLS.get(type)}`,
                },
              ]}
            />
          </div>

          {isCatastrophe && (
            <div className={styles.catastropheHeaderText}>
              {t('executivesPage.catastropheHeaderText')}
            </div>
          )}
          {isLoading ? (
            <Spinner />
          ) : isError ? (
            <ErrorWidget.Error isOnDarkBackground />
          ) : (
            <InfoWrapper isWhiteBackground={true} isPaddingZero={true}>
              <div className={styles.wrapper}>
                <div className={styles.titleFlex}>
                  <h1 className={styles.title}>
                    {isCatastrophe
                      ? EXECUTIVESNAMES_CATASTROPHE.get(`${type}_TITLE`)
                      : EXECUTIVESNAMES.get(type)}
                  </h1>
                  {SEASON_BOUND_EXECUTIVES.includes(type) && !isCatastrophe && (
                    <Select
                      className={styles.select}
                      placeholder=''
                      value={seasonFilter?.toString()}
                      values={seasons}
                      onChange={changeFilter}
                    />
                  )}
                </div>
                <ExecutiveTiles
                  executives={executives}
                  season={
                    SEASON_BOUND_EXECUTIVES.includes(type) && !isCatastrophe
                      ? `20${seasonFilter + seasonPostfix}`
                      : undefined
                  }
                />
              </div>
            </InfoWrapper>
          )}
        </Container>
      </ImageBgPageWrapper>
    );
  }
);

export default ExecutivesPage;
