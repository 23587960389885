import { ReactElement, useEffect, useState } from 'react';
import styles from './GameProtocolArticle.module.scss';
import { getDateWithDayOfWeekCommon } from '../../../../../helpers/helpers';
import ArticleLikesBlock from '../../../../ArticleLikesBlock/ArticleLikesBlock';
import {
  GameWithLogo,
  Highlight,
  InlineBanner,
} from '../../../../../types/Types';
import LinkIcon from '../../../../../assets/link-icon.svg';
import HighlightCard from '../../../../HighlightCard/HighlightCard';
import SliderWrapper from '../../../../SliderWrapper/SliderWrapper';
import {
  getArticleBottomBanner,
  getGameHighlights,
} from '../../../../../api/api';
import { getURI } from 'api/helper';
import ShareInSocials from 'components/common/ShareInSocials/ShareInSocials';
import Article from 'model/entity/loko/type/Article';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import ShowOn from 'components/core/adaptivity/ShowOn';

type GameProtocolArticleProps = {
  article: Article;
  game: GameWithLogo;
};

function GameProtocolArticle({
  article,
  game,
}: GameProtocolArticleProps): ReactElement {
  const handleCopy = async () => {
    await navigator.clipboard.writeText(window.location.href);
  };

  const [highlights, setHighlights] = useState<Highlight[]>([]);
  const [banner, setBanner] = useState('');
  const [bannerLink, setBannerLink] = useState('');
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const bannerObject: InlineBanner = await getArticleBottomBanner();
        setBanner(
          getURI(bannerObject?.attributes?.image?.data?.attributes?.url)
        );
        setBannerLink(bannerObject?.attributes?.link);
      } catch (error: any) {
        console.log(`Failed to load banner: ${error?.message}`);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getHighLights = async () => {
      if (game && game.id) {
        try {
          if (
            game.attributes?.highlights &&
            game.attributes?.highlights?.length
          ) {
            setHighlights(game.attributes.highlights);
          } else {
            const highlightsData = await getGameHighlights(game.id);
            setHighlights(highlightsData);
          }
        } catch (error: any) {
          console.log(`Failed to load hihglights: ${error?.message}`);
        }
      }
    };

    getHighLights();
  }, [game]);

  const renderHighlightsSlider = (slidesToShow: number, slidesToScroll = 1) => {
    return (
      <SliderWrapper
        className={styles.highlightsSlider}
        slides={highlights
          .filter((highlight) => highlight.url !== null)
          .map((highlight) => (
            <HighlightCard
              key={highlight.id}
              src={highlight.url ?? ''}
              caption={highlight.name ?? ''}
            />
          ))}
        dots={false}
        slidesToScroll={slidesToScroll}
        adaptiveHeight
        slidesToShow={slidesToShow}
        speed={500}
        variableWidth
        infinite={false}
      />
    );
  };

  return (
    <>
      <div className={styles.header}>
        <p className={styles.articleDate}>
          {getDateWithDayOfWeekCommon(game.attributes.date)}
        </p>
      </div>
      <div className={styles.newsBlock}>
        <h1 className={styles.newsBlockTitle}>{article.attributes.title}</h1>
        <div
          className={styles.newsBlockContent}
          dangerouslySetInnerHTML={{
            __html: article.attributes.full_text,
          }}
        />
        {banner && (
          <a href={bannerLink}>
            <img src={banner} />
          </a>
        )}
        <div className={styles.newsBlockContentFooter}>
          <p className={styles.author}>{article.attributes.author}</p>
          <ArticleLikesBlock article={article} isActive />
        </div>
        <ShareInSocials />
        {isError ? (
          <ErrorWidget.Error />
        ) : (
          highlights.length > 0 && (
            <div className={styles.highlights}>
              <p className={styles.highlightsTitle}>
                Хайлайты матча: {game.attributes.team_1.data.attributes.name} vs{' '}
                {game.attributes.team_2.data.attributes.name}
              </p>
              <ShowOn largeDesktop smallDesktop largeTablet>
                {renderHighlightsSlider(3, 2)}
              </ShowOn>
              <ShowOn smallTablet>{renderHighlightsSlider(2, 2)}</ShowOn>
              <ShowOn largeMobile smallMobile>
                {renderHighlightsSlider(1)}
              </ShowOn>
            </div>
          )
        )}
      </div>
    </>
  );
}

export default GameProtocolArticle;
