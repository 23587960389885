import { ReactElement, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/store';
import styles from './UserProfileWidget.module.scss';
import { getURI } from 'api/helper';
import { DEFAULT_USER_PHOTO } from 'constants/constants';
import { Modal } from 'antd';
import EditProfileModal from './EditProfileModal/EditProfileModal';
import { useTranslation } from 'react-i18next';

const UserProfileWidget = observer((): ReactElement => {
  const { userStore } = useStore();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getUserName = () => {
    if (userStore.currentUser?.name && userStore.currentUser?.surname) {
      return `${userStore.currentUser.name} ${userStore.currentUser.surname}`;
    } else {
      return userStore.currentUser?.username;
    }
  };

  const onLogoutClick = () => {
    userStore.logout();
  };

  return (
    <div className={styles.userProfileWidget}>
      <div className={styles.imageWrapper}>
        <img
          className={styles.image}
          src={
            userStore.currentUser?.avatar?.url
              ? getURI(userStore.currentUser?.avatar?.url)
              : getURI(DEFAULT_USER_PHOTO)
          }
          alt=''
        />
      </div>
      <div className={styles.profileData}>
        <div className={styles.userName}>{getUserName()}</div>
        <div className={styles.actionButtons}>
          <div className={styles.action} onClick={() => setIsModalOpen(true)}>
            {t('memoryBookPage.userProfileWidget.edit')}
          </div>
          <div className={styles.action} onClick={onLogoutClick}>
            {t('memoryBookPage.userProfileWidget.logout')}
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        centered
        className={styles.modal}
        destroyOnClose={true}
      >
        <EditProfileModal onCancel={() => setIsModalOpen(false)} />
      </Modal>
    </div>
  );
});

export default UserProfileWidget;
