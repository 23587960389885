import { ReactElement, useEffect, useState } from 'react';
import { getURI } from '../../../api/helper';
import { DAYS_SHORT_OF_WEEK } from '../../../constants/constants';
import { ScheduleDay } from '../../../types/Types';
import styles from './Day.module.scss';
import classNames from 'classnames';
import { getScore, getTwoDigitNumber } from '../../../helpers/helpers';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/store';
import { Breakpoints } from 'constants/adaptivity';
import { useMediaQuery } from 'react-responsive';

type SlickDayProps = {
  slickDay: ScheduleDay;
};

const Day = observer(({ slickDay }: SlickDayProps): ReactElement => {
  const [isHome, setIsHome] = useState<boolean | null>(null);
  const [isAway, setIsAway] = useState<boolean | null>(null);
  const [style, setStyle] = useState<string>('');
  const { lokomotivTeamsStore } = useStore();
  const isMobile = useMediaQuery({
    maxWidth: Breakpoints.largeMobile,
  });

  useEffect(() => {
    if (!slickDay.game) {
      setIsHome(null);
      setIsAway(null);
      setStyle('');
    } else if (
      lokomotivTeamsStore.lokomotivTeamsIds.includes(
        slickDay.game.attributes.team_1.data.attributes.id_league_web
      )
    ) {
      setIsHome(true);
      setIsAway(false);
      setStyle(
        `${styles.home} ${styles.customHome} ${
          isMobile ? styles.mobileGame : ''
        }`
      );
    } else if (
      lokomotivTeamsStore.lokomotivTeamsIds.includes(
        slickDay.game.attributes.team_2.data.attributes.id_league_web
      )
    ) {
      setIsHome(false);
      setIsAway(true);
      setStyle(
        `${styles.away} ${styles.customAway} ${
          isMobile ? styles.mobileGame : ''
        }`
      );
    } else {
      setIsHome(null);
      setIsAway(null);
      setStyle(`${isMobile ? styles.mobileDefault : ''}`);
    }
  }, [slickDay, lokomotivTeamsStore.lokomotivTeamsIds]);

  function getImageOpponent() {
    return getURI(
      slickDay.game?.attributes.team_2?.data?.attributes.logo?.data?.attributes
        .url || ''
    );
  }

  function getImageLokomotiv() {
    return getURI(
      slickDay.game?.attributes.team_1?.data?.attributes.logo?.data?.attributes
        .url || ''
    );
  }

  return (
    <div
      className={classNames(
        style,
        isMobile ? styles.slickDayMobile : styles.slickDay
      )}
      data-slick-index='3'
      aria-hidden='true'
    >
      <div
        className={classNames({
          [styles.dayMobileHome]: isMobile && (isHome || isAway),
          [styles.dayMobile]: isMobile && !isHome && !isAway,
          [styles.day]: !isMobile,
        })}
      >
        <div>
          <div
            className={classNames(styles.dateNumber, {
              [styles.dateNumberHome]: isHome === true,
              [styles.dateNumberAway]: isHome === false,
            })}
          >
            {getTwoDigitNumber(slickDay.day.getDate().toString())}
          </div>

          <div
            className={
              slickDay.game
                ? classNames(styles.dateName, styles.greyText)
                : styles.dateName
            }
          >
            {DAYS_SHORT_OF_WEEK.get(slickDay.day.getDay()).toUpperCase()}
          </div>
          {slickDay.game && (
            <div className={styles.gameWrapper}>
              <div className={styles.logoWrapper}>
                <img
                  className={styles.customLogo}
                  src={getImageLokomotiv()}
                  alt='lokomotiv logo'
                />
              </div>
              <div className={styles.divider}>
                <span>
                  {getScore(slickDay.game.attributes.score_1)}:
                  {getScore(slickDay.game.attributes.score_2)}
                </span>
              </div>
              <div className={styles.logoWrapper}>
                <img
                  className={styles.customLogo}
                  src={getImageOpponent()}
                  alt='opponent logo'
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default Day;
