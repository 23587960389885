import { useState, useEffect } from 'react';
import { getURI } from '../../../../api/helper';
import { loadHightResolutionImage } from '../../../../helpers/helpers';
import Image from 'model/entity/loko/type/Image';

type ImageLoaderProps = {
  image: Image | string;
  alt?: string;
  className?: string;
  styles?: React.CSSProperties;
};

const ImageLoader = ({ image, alt, className, styles }: ImageLoaderProps) => {
  const [imgSrc, setImgSrc] = useState<string>();
  const [isVideo, setIsVideo] = useState<boolean>(false);
  const VIDEO_EXTS = ['mov', 'mp4', 'mpeg', 'wmv', 'avi', 'flv'];

  useEffect(() => {
    if (image) {
      if (typeof image === 'string') {
        setImgSrc(image);
      } else {
        const ext = image?.attributes?.url?.substring(
          image?.attributes?.url?.lastIndexOf('.') + 1
        );
        if (VIDEO_EXTS.includes(ext.toLowerCase())) {
          setIsVideo(true);
        }

        const lowResSrc = image.attributes.formats?.small?.url;
        setImgSrc(lowResSrc);
        loadHightResolutionImage(image.attributes.url, setImgSrc);
      }
    }
  }, [image]);

  useEffect(() => {
    if (isVideo && typeof image !== 'string') {
      setImgSrc(image?.attributes?.url);
    }
  }, [image, isVideo]);

  return imgSrc ? (
    isVideo ? (
      <video
        className={className}
        style={styles}
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={getURI(imgSrc)} />
      </video>
    ) : (
      <img
        src={getURI(imgSrc)}
        className={className}
        alt={alt || ''}
        style={styles}
      />
    )
  ) : null;
};
export default ImageLoader;
