import { ReactElement } from 'react';
import styles from './SidePartners.module.scss';
import { useStore } from 'store/store';
import { getURI } from 'api/helper';

function SidePartners(): ReactElement {
  const { sideMenuStore } = useStore();

  return (
    <div className={styles.sideMenuWrapper}>
      <div className={styles.partnerSection}>
        {sideMenuStore.sideMenuPartners.map((partner) => {
          return (
            partner.partner.enabled &&
            partner.priority < 3 && (
              <div
                key={`${partner.id}_${partner.partner.name}`}
                className={styles.imageWrapper}
                onClick={() => window.open(partner.partner.url)}
              >
                <img src={getURI(partner.partner.logo.url)} alt='' />
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}

export default SidePartners;
