import { useState, useEffect, useCallback } from "react";
import { getURI } from "../../../../api/helper";
import { loadHightResolutionImage } from "../../../../helpers/helpers";
import Image from "model/entity/loko/type/Image";

type BackgroundImageLoaderProps = {
  image: Image | string;
  styles?: React.CSSProperties;
  className?: string;
};

const BackgroundImageLoader = ({
  image,
  styles,
  className,
}: BackgroundImageLoaderProps) => {
  const [elementStyles, setElementStyles] = useState<React.CSSProperties>();
  
const setHightResolutionImage = useCallback((url: string) => {
    setElementStyles({
      background: `url(${getURI(url)})`,
      ...styles,
    });
  }, [styles]);

  useEffect(() => {
    if (image) {
      if (typeof image === "string") {
        setElementStyles({
          background: `url(${getURI(image)})`,
          ...styles,
        });
      } else {
        const lowResSrc = image.attributes.formats.small?.url || "";

        setElementStyles({
          background: `url(${getURI(lowResSrc)})`,
          ...styles,
        });
        loadHightResolutionImage(image.attributes.url, setHightResolutionImage);
      }
    }
  }, [image, setHightResolutionImage, styles]);

  return <div style={elementStyles} className={className} />;
};
export default BackgroundImageLoader;
