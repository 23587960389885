import { useEffect, useState } from 'react';
import {
  getDefaultPlayerImage,
  getExecutiveById,
  getExecutivesByType,
} from '../../../api/api';
import {
  DefaultImageObject,
  ExecutiveDto,
  ExecutiveType,
} from '../../../types/Types';
import InfoWrapper from '../../Wrappers/InfoWrapper/InfoWrapper';
import ImageBgPageWrapper from '../../common/ImageBgPageWrapper/ImageBgPageWrapper';
import Container from '../../common/Container/Container';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import { observer } from 'mobx-react-lite';
import { useParams, useSearchParams } from 'react-router-dom';
import ExecutiveSmallTiles from '../../ExecutivesSmallTiles/ExecutivesSmallTiles';
import { getURI } from '../../../api/helper';
import BioBox from '../../PlayerPage/Bio/BioBox/BioBox';
import SetRespError, {
  getDate,
  getFullExecutiveName,
} from '../../../helpers/helpers';
import styles from './ManagementPage.module.scss';
import ExecutiveTabsWithInfo from '../../ExecutiveTabsWithInfo/ExecutiveTabsWithInfo';
import ShowOn from 'components/core/adaptivity/ShowOn';
import { DEFAULT_IMAGE_NAME, LOKOMOTIV_CODE } from 'constants/constants';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import ImageLoader from 'components/common/ImageLoaders/ImageLoader/ImageLoader';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../ErrorPage/ErrorPage';
import Spinner from 'components/Spinner/Spinner';

const ManagementPage = observer(() => {
  const { t } = useTranslation();
  const [management, setManagement] = useState<ExecutiveDto>();
  const [otherManagement, setOtherManagement] = useState<ExecutiveDto[]>([]);
  const [defaultPhoto, setDefaultPhoto] = useState<DefaultImageObject>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isNotFoundError, setIsNotFoundError] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { managementId } = useParams();

  useEffect(() => {
    const getExecutiveInfo = async () => {
      try {
        const season = searchParams.get('season');
        const managementResponse = await getExecutiveById(Number(managementId));
        const otherManagementResponse = await getExecutivesByType(
          ExecutiveType.MANAGER,
          season || '',
          true
        );

        const filteredManagement = otherManagementResponse?.data.filter(
          (item: ExecutiveDto) => item.id !== Number(managementId)
        );

        setManagement(managementResponse.data);
        setOtherManagement(filteredManagement);
      } catch (e: any) {
        SetRespError(e, setIsNotFoundError, setIsError);
      }

      try {
        const photo = await getDefaultPlayerImage(DEFAULT_IMAGE_NAME);
        setDefaultPhoto(photo?.data[0]);
      } catch (e: any) {
        console.error(e?.response?.data?.error || e?.message);
      }
    };

    getExecutiveInfo();
  }, [managementId]);

  if (management && management?.attributes.type !== ExecutiveType.MANAGER)
    return <ErrorPage />;

  if (
    management &&
    management?.attributes?.team_filter.data.every(
      (team) => team.attributes.code !== LOKOMOTIV_CODE
    )
  )
    return <ErrorPage />;

  return (
    <ImageBgPageWrapper
      imageInfo={{
        img_url: management?.attributes?.bg_photo?.data?.attributes?.url ?? '',
        gradient_height: 200,
        image_height: 700,
      }}
    >
      <Container paddingTop='50px' paddingBottom='0px'>
        <BreadcrumbsWrapper
          breadcrumbs={[
            { name: t('managementPage.breadcrumbParent'), url: '/management' },
            {
              name: getFullExecutiveName(management),
              url: `/manager/${managementId}`,
            },
          ]}
        />
        {isNotFoundError ? (
          <ErrorWidget.NotFound isOnDarkBackground />
        ) : isError ? (
          <ErrorWidget.Error isOnDarkBackground />
        ) : management ? (
          <>
            <div className={styles.executiveHeader}>
              <div className={styles.info}>
                <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
                  <p className={styles.position}>
                    {management?.attributes?.position ?? ''}
                  </p>
                  <p className={styles.name}>
                    {getFullExecutiveName(management)}
                  </p>
                </ShowOn>
                <ShowOn largeMobile smallMobile>
                  <p className={styles.positionMobile}>
                    {management?.attributes?.position ?? ''}
                  </p>
                  <p className={styles.nameMobile}>
                    {getFullExecutiveName(management)}
                  </p>
                </ShowOn>
              </div>
              <div className={styles.mainInfo}>
                <ShowOn largeDesktop smallDesktop largeTablet>
                  <div className={styles.imageWrapper}>
                    <ImageLoader
                      image={
                        management?.attributes?.photo?.data?.attributes?.url ??
                        defaultPhoto?.attributes?.image?.data?.attributes
                          ?.url ??
                        ''
                      }
                      alt={
                        `${management?.attributes?.surname} ${management?.attributes?.name}` ??
                        'executive'
                      }
                    />
                  </div>
                </ShowOn>
                <div className={styles.bioWrapper}>
                  {management?.attributes?.birth && (
                    <>
                      <BioBox
                        name={t('managementPage.bio.birthDate')}
                        value={getDate(management?.attributes?.birth)}
                      />
                      <BioBox
                        name={t('managementPage.bio.birthPlace')}
                        value={
                          management?.attributes?.birth_place ||
                          t('managementPage.bio.noValue')
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <InfoWrapper
              isWhiteBackground={true}
              isPaddingZero={true}
              borderRadius='10px'
            >
              <>
                {management && <ExecutiveTabsWithInfo executive={management} />}
              </>
            </InfoWrapper>
          </>
        ) : (
          <Spinner />
        )}
        {otherManagement?.length > 0 && (
          <div className={styles.otherExecutives}>
            <p className={styles.title}>{t('managementPage.otherManagers')}</p>
            <ExecutiveSmallTiles executives={otherManagement} />
          </div>
        )}
      </Container>
    </ImageBgPageWrapper>
  );
});

export default ManagementPage;
