import { ReactElement } from 'react';
import styles from './MFSKinfo.module.scss';
import ShowOn from 'components/core/adaptivity/ShowOn';
import { ComplexInformationResponse } from 'types/Types';
import BioBox from 'components/PlayerPage/Bio/BioBox/BioBox';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Breakpoints } from 'constants/adaptivity';
import { useMediaQuery } from 'react-responsive';

type MFSKPageTypes = {
  aboutMfsk: ComplexInformationResponse;
};

const MFSKPage = ({ aboutMfsk }: MFSKPageTypes): ReactElement => {
  const { t } = useTranslation();

  const isLargeTablet = useMediaQuery({
    minWidth: Breakpoints.largeTablet + 1,
    maxWidth: Breakpoints.smallDesktop,
  });

  const renderInformationAboutMFSK = () => (
    <div className={styles.bioWrapper}>
      <div className={styles.bio}>
        <BioBox
          name={t('infrastructurePage.about.area')}
          value={t('infrastructurePage.about.areaValue', {
            value: aboutMfsk?.area || 0,
          })}
          nameFontSize={isLargeTablet ? 16 : 18}
          valueFontSize={isLargeTablet ? 18 : 22}
        />
        <BioBox
          name={t('infrastructurePage.about.capacity')}
          value={t('infrastructurePage.about.capacityValue', {
            value: aboutMfsk?.capacity || 0,
          })}
          nameFontSize={isLargeTablet ? 16 : 18}
          valueFontSize={isLargeTablet ? 18 : 22}
        />
      </div>
    </div>
  );

  const renderMapArticle = () => (
    <div className={classNames(styles.article)}>
      <div className={classNames(styles.container, styles.arenaContainer)}>
        <div
          className={classNames(
            styles.contentContainer,
            styles.arenaContentContainer
          )}
        >
          <h2 className={styles.title}>
            {aboutMfsk?.title || t('infrastructurePage.about.headerNotFound')}
          </h2>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html:
                aboutMfsk?.description ||
                t('infrastructurePage.about.descriptionNotFound'),
            }}
          />

          {renderInformationAboutMFSK()}
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <ShowOn largeDesktop smallDesktop largeTablet>
          <div className={classNames(styles.article)}>
            <div className={classNames(styles.arenaContainer)}>
              <div className={styles.wrapper}>
                <div className={styles.contentWrapper}>
                  <div
                    className={classNames(
                      styles.contentContainer,
                      styles.arenaContentContainer,
                      styles.container
                    )}
                  >
                    <h2 className={styles.title}>
                      {aboutMfsk?.title ||
                        t('infrastructurePage.about.headerNotFound')}
                    </h2>
                    <div
                      className={styles.content}
                      dangerouslySetInnerHTML={{
                        __html:
                          aboutMfsk?.description ||
                          t('infrastructurePage.about.descriptionNotFound'),
                      }}
                    />

                    {renderInformationAboutMFSK()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ShowOn>
        <ShowOn smallTablet largeMobile smallMobile>
          {renderMapArticle()}
        </ShowOn>
      </div>
    </div>
  );
};

export default MFSKPage;
