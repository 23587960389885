import { ReactElement, useState } from 'react';
import { ExecutiveDto } from 'types/Types';
import styles from './ExecutiveTileMobile.module.scss';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_USER_PHOTO } from 'constants/constants';
import ImageLoader from '../common/ImageLoaders/ImageLoader/ImageLoader';
import { getFullExecutiveName } from 'helpers/helpers';
import { ReactComponent as Arrow } from 'assets/upper_right_arrow.svg';
import { useTranslation } from 'react-i18next';
import Colors from 'constants/colors';

type ExecutiveTileProps = {
  executive: ExecutiveDto;
  season?: string;
};

const ExecutiveTileMobile = ({
  executive,
  season,
}: ExecutiveTileProps): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { id, attributes } = executive;

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={styles.wrapper}
      onClick={() =>
        attributes?.is_catastrophe
          ? navigate(`/catastrophe/${attributes?.type}/${id}`)
          : navigate(
              `/${attributes?.type}/${id}${season ? `?season=${season}` : ''}`
            )
      }
    >
      {isHovered ? (
        <ImageLoader
          className={styles.image}
          image={attributes?.bg_photo?.data || DEFAULT_USER_PHOTO}
          alt={attributes?.name}
        />
      ) : (
        <ImageLoader
          className={styles.image}
          image={attributes?.photo?.data || DEFAULT_USER_PHOTO}
          alt={attributes?.name}
        />
      )}
      <div className={styles.information}>
        <div>
          <div className={styles.fullnameWrapper}>
            <p className={styles.fullname}>
              {getFullExecutiveName(executive) ?? ''}
            </p>
          </div>
          <p className={styles.position}>{attributes?.position ?? ''}</p>
        </div>
        <div className={styles.linkWrapper}>
          <span className={styles.linkText}>
            {t('executivesPage.moreInfo')}
          </span>
          <Arrow className={styles.arrowIcon} color={Colors.PRIMARY_RED} />
        </div>
      </div>
    </div>
  );
};

export default ExecutiveTileMobile;
