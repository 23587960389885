import { ArenaPointsType } from '../types/Types';
import ParkingIcon from '../assets/icons/arena_points/VIPParking.png';
import RoadIcon from '../assets/icons/arena_points/Road_2.png';
import Entrance from '../assets/icons/arena_points/Entrance.png';
import TicketOffice from '../assets/icons/arena_points/Tickets_office.png';
import Hall from '../assets/icons/arena_points/Ice_hockey_hall.png';
import Office from '../assets/icons/arena_points/Office.png';
import TransportStop from '../assets/icons/arena_points/Transport_stop.png';
import PressEntrance from '../assets/icons/arena_points/press_entrance_icon.png';
import PlayersEntrance from '../assets/icons/arena_points/players_entrance_icon.png';
import ClubStore from '../assets/icons/arena_points/clubstore_icon.png';
import Lenta from '../assets/icons/arena_points/lenta_icon.png';
import Obelisk from '../assets/icons/arena_points/obelisk_icon.png';
import Vip from '../assets/icons/arena_points/vip_icon.png';

export const arenaPoints: Array<ArenaPointsType> = [
  {
    bottom: 13,
    right: 14,
    alt: 'parking-1',
    icon: ParkingIcon,
  },
  {
    bottom: 17,
    right: 9,
    alt: 'parking-2',
    icon: ParkingIcon,
  },
  {
    bottom: 22,
    right: 4,
    alt: 'parking-3',
    icon: ParkingIcon,
  },
  {
    bottom: 13,
    right: 40,
    alt: 'road-1',
    icon: RoadIcon,
  },
  {
    bottom: 33,
    right: 61,
    alt: 'entrance',
    icon: Entrance,
  },
  {
    bottom: 36,
    right: 72,
    alt: 'tickets-office',
    icon: TicketOffice,
  },
  {
    bottom: 41,
    right: 85,
    alt: 'transport-stop',
    icon: TransportStop,
  },
  {
    bottom: 35,
    right: 93,
    alt: 'road-2',
    icon: RoadIcon,
  },
  {
    bottom: 58,
    right: 61,
    alt: 'hall',
    icon: Hall,
  },
  {
    bottom: 57,
    right: 43,
    alt: 'office',
    icon: Office,
  },
  {
    bottom: 48,
    right: 38,
    alt: 'parking-4',
    icon: ParkingIcon,
  },
  {
    bottom: 43,
    right: 33,
    alt: 'parking-5',
    icon: ParkingIcon,
  },
  {
    bottom: 35,
    right: 30,
    alt: 'parking-6',
    icon: ParkingIcon,
  },
  {
    bottom: 57.5,
    right: 71,
    alt: 'press-entrance',
    icon: PressEntrance,
  },
  {
    bottom: 58,
    right: 51,
    alt: 'players-entrance',
    icon: PlayersEntrance,
  },
  {
    bottom: 52,
    right: 42,
    alt: 'club-store',
    icon: ClubStore,
  },
  {
    bottom: 23,
    right: 30.5,
    alt: 'lenta',
    icon: Lenta,
  },
  {
    bottom: 27,
    right: 39.5,
    alt: 'obelisk',
    icon: Obelisk,
  },
  {
    bottom: 47,
    right: 44.8,
    alt: 'vip-entrance',
    icon: Vip,
  },
];
