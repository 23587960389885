import { AdvertisingWidgetDto } from 'types/Types';
import styles from './ImageInSideTypeWidget.module.scss';
import { getURI } from 'api/helper';
import { isContainsHTMLTags } from 'helpers/helpers';

type ImageInSideTypeWidgetType = {
  advertising: AdvertisingWidgetDto;
};

function ImageInSideTypeWidget({ advertising }: ImageInSideTypeWidgetType) {
  return (
    <div className={styles.imageInSideContainer}>
      <div className={styles.leftSideContainer}>
        <div className={styles.leftSideInnerText}>
          {isContainsHTMLTags(advertising.attributes.text) ? (
            <div
              dangerouslySetInnerHTML={{
                __html: advertising.attributes.text || '',
              }}
            />
          ) : (
            advertising.attributes.text
          )}
        </div>
        <div className={styles.containerButtonImageInSide}>
          <a
            className={styles.widgetBtn}
            target='_blank'
            href={advertising.attributes.link}
          >
            <div className={styles.alignBtnImageInSide}>
              <span className={styles.widgetBtnOrder}>Заказать</span>
            </div>
          </a>
        </div>
      </div>
      <div className={styles.rightSideContainer}>
        <img
          src={getURI(
            advertising.attributes?.main_image?.data.attributes.url || ''
          )}
          style={{ margin: advertising.attributes.padding }}
        />
      </div>
    </div>
  );
}

export default ImageInSideTypeWidget;
