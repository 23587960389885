import { ReactElement } from 'react';
import classNames from 'classnames';
import { getURI } from '../../../../api/helper';
import styles from './BioBox.module.scss';
import ShowOn from 'components/core/adaptivity/ShowOn';

type BioBoxProps = {
  name: string;
  value: string | number;
  displayLogo?: string;
  dopInfo?: string;
  nameFontSize?: number;
  valueFontSize?: number;
};

function BioBox({
  name,
  value,
  displayLogo,
  dopInfo,
  nameFontSize = 16,
  valueFontSize = 16,
}: BioBoxProps): ReactElement {
  return (
    <div className={styles.bioBox}>
      <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
        <div
          className={styles.bioBoxHeading}
          style={{ fontSize: `${nameFontSize}px` }}
        >
          {name}
        </div>
      </ShowOn>
      <ShowOn largeMobile smallMobile>
        <div className={styles.bioBoxHeadingSmall}>{name}</div>
      </ShowOn>
      {displayLogo ? (
        <div className={styles.bioBoxDataWithLogo}>
          <img
            src={displayLogo ? getURI(displayLogo) : undefined}
            alt={value.toString()}
          />
          <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
            <span
              className={classNames(styles.bioBoxData, styles.bioBoxDataMargin)}
            >
              {value} {dopInfo}
            </span>
          </ShowOn>
          <ShowOn largeMobile smallMobile>
            <span
              className={classNames(
                styles.bioBoxDataSmall,
                styles.bioBoxDataMargin
              )}
            >
              {value} {dopInfo}
            </span>
          </ShowOn>
        </div>
      ) : (
        <>
          <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
            <div
              className={styles.bioBoxData}
              style={{ fontSize: `${valueFontSize}px` }}
            >
              {value} {dopInfo}
            </div>
          </ShowOn>
          <ShowOn largeMobile smallMobile>
            <div className={styles.bioBoxDataSmall}>
              {value} {dopInfo}
            </div>
          </ShowOn>
        </>
      )}
    </div>
  );
}

export default BioBox;
