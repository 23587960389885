import { useEffect, useRef, useState } from 'react';
import { useStore } from 'store/store';
import printer from '../../assets/icons/printer-svgrepo-com.svg';

import {
  getPlayersFromSeasonWithPaging,
  getDefaultImages,
  getSeasonByCode,
  getSeasonStats,
  getSeasonsByTeamId,
} from 'api/api';
import {
  DefaultImageObject,
  PlayerDto,
  PlayerSeasonStatsDto,
  PlayerSeasonStatsDtoReadable,
  Roster,
  Season,
  SortElement,
  StandingsTypes,
} from 'types/Types';
import {
  customSort,
  mapPlayersToReadable,
  sortSeasonOptions,
} from 'helpers/helpers';

import Spinner from '../Spinner/Spinner';
import GoalkeepersStats from './GoalkeepersStats/GoalkeepersStats';
import NotGoalkeepersStats from './NotGoalkeeperStats/NotGoalkeeperStats';
import InfoWrapper from '../Wrappers/InfoWrapper/InfoWrapper';
import styles from './StatsLokomotivPage.module.scss';
import { observer } from 'mobx-react-lite';
import Breadcrumbs from '../common/Breadcrumbs/Breadcrumbs';
import ImageBgPageWrapper from '../common/ImageBgPageWrapper/ImageBgPageWrapper';
import ShowOn from 'components/core/adaptivity/ShowOn';
import { useParams } from 'react-router-dom';
import {
  defaultForwardSortArray,
  defaultGoalKeeperSortArray,
} from 'constants/constants';
import Select, { SelectOption } from 'components/common/Select/Select';
import { useTranslation } from 'react-i18next';
import StatsLokomotivPagePDF from './StatsLokomotivPagePDF';
import { Modal } from 'antd';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';

const StatsPage = observer(() => {
  const { t } = useTranslation();
  const { currentSeasonStore } = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [goalkeepers, setGoalkeepers] = useState<Array<PlayerSeasonStatsDto>>(
    []
  );
  const [defenders, setDefenders] = useState<Array<PlayerSeasonStatsDto>>([]);
  const [forwards, setForwards] = useState<Array<PlayerSeasonStatsDto>>([]);
  const [playersLocal, setPlayersLocal] = useState<Array<PlayerDto>>();
  const [goalkeepersStats, setGoalkeepersStats] = useState<
    Array<PlayerSeasonStatsDtoReadable>
  >([]);
  const [notGoalkeepersStats, setNotGoalkeepersStats] = useState<
    Array<PlayerSeasonStatsDtoReadable>
  >([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [seasonFilterNew, setSeasonFilterNew] = useState<string>('');
  const [seasonsOptions, setSeasonsOptions] =
    useState<Array<{ key: string; value: string }>>();
  const [defaultImages, setDefaultImages] = useState<DefaultImageObject[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [userSorting, setUserSorting] = useState<Array<SortElement>>([]);
  const { teamCode } = useParams();
  const [open, setOpen] = useState(false);
  const [isDefaultSort, setIsDefaultSort] = useState<boolean>(false);
  const goalkeepersRef = useRef<HTMLTableSectionElement>(null);
  const notGoalkeepersRef = useRef<HTMLTableSectionElement>(null);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    const seasonCode = currentSeasonStore.getSeasonByCode(teamCode);

    const getData = async () => {
      if (currentSeasonStore.isLoading) {
        setIsLoading(true);
        setIsError(false);
      } else if (seasonCode) {
        setIsError(false);
        const seasonParam = await getSeasonByCode(seasonCode);
        const seasonList = await getSeasonsByTeamId(
          seasonParam?.data[0]?.attributes.team
        );

        const seasonOptions = getSeasonsFilterOptions(seasonList?.data);
        const currentSeasonOption = seasonOptions.find(
          (option) => option.key === seasonCode
        );

        setSeasonsOptions(
          seasonOptions.sort((a, b) => sortSeasonOptions(a.key, b.key))
        );
        setSeasonFilterNew(
          currentSeasonOption ? currentSeasonOption.key : seasonOptions[0]?.key
        );
      } else {
        setIsLoading(false);
        setIsError(true);
      }
    };
    getData();
  }, [teamCode, currentSeasonStore.currentSeason]);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        setIsError(false);
        const resp = await getSeasonStats(seasonFilterNew);

        const allSeasons = seasonFilterNew.slice(0, 7);
        const playersResp = await getPlayersFromSeasonWithPaging(allSeasons);
        setGoalkeepers(
          resp?.PlayerStatsList?.PlayerStats?.filter((x: any) => {
            return x['@_pos'] === 'в' && x['@_jn'] !== '-';
          })
        );
        setDefenders(
          resp?.PlayerStatsList?.PlayerStats?.filter((x: any) => {
            return x['@_pos'] === 'з' && x['@_jn'] !== '-';
          })
        );
        setForwards(
          resp?.PlayerStatsList?.PlayerStats?.filter((x: any) => {
            return x['@_pos'] === 'н' && x['@_jn'] !== '-';
          })
        );
        setPlayersLocal(playersResp?.data);
      } catch (e: any) {
        const error = e?.response?.data?.error || e?.message;
        if (error) {
          setIsError(true);
        }
      } finally {
        setIsLoading(false);
      }

      try {
        const images = await getDefaultImages();
        setDefaultImages(images?.data);
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      }
    };
    if (seasonFilterNew) {
      getData();
    }
  }, [seasonFilterNew]);

  useEffect(() => {
    initPlayersWithStats(goalkeepers, setGoalkeepersStats, true);
  }, [playersLocal, goalkeepers]);

  useEffect(() => {
    initPlayersWithStats(
      [...defenders, ...forwards],
      setNotGoalkeepersStats,
      false
    );
  }, [playersLocal, defenders, forwards]);

  useEffect(() => {
    const isGoalKeeper = activeTab === 1;
    const players = isGoalKeeper ? goalkeepersStats : notGoalkeepersStats;
    const defaultSortArray = isGoalKeeper
      ? defaultGoalKeeperSortArray
      : defaultForwardSortArray;
    const updatedValue = customSort(players, defaultSortArray, true);

    if (isGoalKeeper) {
      setGoalkeepersStats([...updatedValue]);
    } else {
      setNotGoalkeepersStats([...updatedValue]);
    }

    setUserSorting([]);
  }, [seasonFilterNew]);

  function getSeasonsFilterOptions(
    seasonsList: Season[]
  ): Array<{ key: string; value: string }> {
    const seasonsOptions = new Map();
    seasonsList &&
      seasonsList.forEach((season) => {
        !season.attributes.code.endsWith('prip') &&
          seasonsOptions.set(
            season.attributes.code,
            `${season.attributes.name} ${season.attributes.years_interval}`
          );
      });
    return Array.from(seasonsOptions, ([key, value]) => ({ key, value }));
  }

  function initPlayersWithStats(
    players: Array<PlayerSeasonStatsDto>,
    setStateFunction: React.Dispatch<
      React.SetStateAction<PlayerSeasonStatsDtoReadable[]>
    >,
    isGoalKeeper: boolean
  ) {
    let playersReadableStats = mapPlayersToReadable(players);
    if (playersLocal && playersLocal.length > 0) {
      for (let i = 0; i < playersReadableStats.length; i++) {
        const playerLocal = playersLocal.find(
          (x) => x.attributes.id_league_web === playersReadableStats[i].id
        );
        if (playerLocal) {
          playersReadableStats[i]['idlocal'] = playerLocal.id;
          playersReadableStats[i]['namelocal'] = playerLocal.attributes.name;
          playersReadableStats[i]['photo'] = playerLocal.attributes.photo;
          const numberPlayer = getNumber(playerLocal);
          if (numberPlayer) {
            playersReadableStats[i]['number'] = numberPlayer;
          }
        }
      }
    }
    const defaultSortArray = isGoalKeeper
      ? defaultGoalKeeperSortArray
      : defaultForwardSortArray;
    playersReadableStats = customSort(
      playersReadableStats,
      defaultSortArray,
      true
    );

    setStateFunction(playersReadableStats);
  }

  const getNumber = (playerLocal: PlayerDto | undefined): number => {
    const roster = playerLocal?.attributes?.rosters?.data.find(
      (item: Roster) =>
        item?.attributes?.season?.data?.attributes?.name === seasonFilterNew
    );
    const resp = roster?.attributes.number || 0;

    return resp;
  };

  function updateSorting(
    key: string,
    isGoalKeeper: boolean,
    type: StandingsTypes
  ) {
    let newUserSorting = userSorting;
    const sortParam = newUserSorting.find((sortParam) => sortParam.key === key);
    if (!sortParam) {
      newUserSorting.push({
        key: key,
        desc: true,
        type: type,
      });
    } else {
      if (sortParam.desc === true) {
        sortParam.desc = isDefaultSort;
      } else {
        newUserSorting = newUserSorting.filter(
          (newSortParam) => newSortParam.key != sortParam.key
        );
      }
    }

    const defaultSortArray = isGoalKeeper
      ? defaultGoalKeeperSortArray
      : defaultForwardSortArray;
    const players = isGoalKeeper ? goalkeepersStats : notGoalkeepersStats;
    const updatedValue = customSort(
      players,
      [...newUserSorting, ...defaultSortArray],
      isDefaultSort
    );

    setUserSorting(newUserSorting);
    if (isGoalKeeper) {
      setGoalkeepersStats([...updatedValue]);
    } else {
      setNotGoalkeepersStats([...updatedValue]);
    }
  }

  function getSelectOptions(
    options: { key: string; value: string }[]
  ): SelectOption[] {
    return options.map((option) => {
      return {
        value: option.key,
        displayValue: option.value,
      };
    });
  }

  function renderSelect() {
    return (
      <Select
        placeholder={
          seasonsOptions
            ? seasonsOptions[0].value
            : t('statsLokomotivPage.season')
        }
        values={getSelectOptions(seasonsOptions || [])}
        onChange={setSeasonFilterNew}
      />
    );
  }

  function onChangeTab(tabNumber: number) {
    const isGoalKeeper = tabNumber === 1;
    const players = isGoalKeeper ? goalkeepersStats : notGoalkeepersStats;
    const defaultSortArray = isGoalKeeper
      ? defaultGoalKeeperSortArray
      : defaultForwardSortArray;
    const updatedValue = customSort(players, defaultSortArray, true);

    if (isGoalKeeper) {
      setGoalkeepersStats([...updatedValue]);
    } else {
      setNotGoalkeepersStats([...updatedValue]);
    }

    setUserSorting([]);
    setActiveTab(tabNumber);
    if (goalkeepersRef.current) {
      goalkeepersRef.current.scrollLeft = 0;
    }
    if (notGoalkeepersRef.current) {
      notGoalkeepersRef.current.scrollLeft = 0;
    }
  }

  const renderDesktopAndTablet = () => {
    return (
      <InfoWrapper
        isWhiteBackground={false}
        borderRadius='0px'
        innerPaddingTop='20px'
      >
        <div className={styles.breadcrumbsWrapper}>
          <Breadcrumbs
            breadcrumbs={[
              {
                name: t('statsLokomotivPage.breadcrumb'),
                url: `/stats/${teamCode}`,
              },
            ]}
            isWhiteColor={false}
          />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.innerWrapper}>
            <div className={styles.titleFlex}>
              <h1 className={styles.title}>{t('statsLokomotivPage.header')}</h1>
              <div className={styles.selectWrapper}>
                <ShowOn largeDesktop smallDesktop largeTablet>
                  <button onClick={showModal} className={styles.printerBtn}>
                    <img
                      src={printer}
                      width={20}
                      height={20}
                      alt='print-icon'
                    />
                  </button>
                </ShowOn>
                {renderSelect()}
              </div>
            </div>
            <Modal
              open={open}
              title='Статистика команды'
              width={1000}
              footer={[]}
              onCancel={handleCancel}
            >
              <StatsLokomotivPagePDF
                notGoalkeepersStats={notGoalkeepersStats}
                goalkeepersStats={goalkeepersStats}
              />
            </Modal>
            <div className={styles.tabBar} id='tab-bar'>
              <a
                onClick={() => onChangeTab(0)}
                className={activeTab === 0 ? styles.active : ''}
                data-tab='goalkeepers'
              >
                {t('statsLokomotivPage.players')}
              </a>
              <a
                onClick={() => onChangeTab(1)}
                className={activeTab === 1 ? styles.active : ''}
                data-tab='notGoalkeepers'
              >
                {t('statsLokomotivPage.goalkeepers')}
              </a>
            </div>

            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <span>Статистика команды не найдена</span>
            ) : (
              <>
                {activeTab === 0 ? (
                  <div id='notGoalkeepers'>
                    <table className={styles.table}>
                      <div className={styles.statsTableWrapper}>
                        <NotGoalkeepersStats
                          notGoalkeepersStats={notGoalkeepersStats}
                          updateSorting={updateSorting}
                          defImg={defaultImages}
                        />
                      </div>
                    </table>
                  </div>
                ) : (
                  <div id='goalkeepers'>
                    <table className={styles.table}>
                      <div className={styles.statsTableWrapper}>
                        <GoalkeepersStats
                          goalkeepersStats={goalkeepersStats}
                          updateSorting={updateSorting}
                          defImg={defaultImages}
                          setIsDefaultSort={setIsDefaultSort}
                        />
                      </div>
                    </table>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </InfoWrapper>
    );
  };

  const renderMobile = () => {
    return (
      <InfoWrapper
        isWhiteBackground={false}
        borderRadius='0px'
        innerPaddingTop='20px'
      >
        <div className={styles.breadcrumbsWrapper}>
          <Breadcrumbs
            breadcrumbs={[
              {
                name: t('statsLokomotivPage.breadcrumb'),
                url: `/stats/${teamCode}`,
              },
            ]}
            isWhiteColor={false}
          />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.innerWrapper}>
            <div className={styles.titleFlex}>
              <h1 className={styles.title}>{t('statsLokomotivPage.header')}</h1>
              {renderSelect()}
            </div>

            <div className={styles.tabBar} id='tab-bar'>
              <a
                onClick={() => onChangeTab(0)}
                className={activeTab === 0 ? styles.active : ''}
              >
                {t('statsLokomotivPage.players')}
              </a>
              <a
                onClick={() => onChangeTab(1)}
                className={activeTab === 1 ? styles.active : ''}
              >
                {t('statsLokomotivPage.goalkeepers')}
              </a>
            </div>

            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <span>{t('statsLokomotivPage.statsNotFound')}</span>
            ) : (
              <>
                {activeTab === 0 ? (
                  <div id='notGoalkeepers'>
                    <table className={styles.table}>
                      <div
                        className={styles.statsTableWrapper}
                        ref={notGoalkeepersRef}
                      >
                        <NotGoalkeepersStats
                          notGoalkeepersStats={notGoalkeepersStats}
                          updateSorting={updateSorting}
                          defImg={defaultImages}
                        />
                      </div>
                    </table>
                  </div>
                ) : (
                  <div id='goalkeepers'>
                    <table className={styles.table}>
                      <div
                        className={styles.statsTableWrapper}
                        ref={goalkeepersRef}
                      >
                        <GoalkeepersStats
                          goalkeepersStats={goalkeepersStats}
                          updateSorting={updateSorting}
                          defImg={defaultImages}
                          setIsDefaultSort={setIsDefaultSort}
                        />
                      </div>
                    </table>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </InfoWrapper>
    );
  };

  return (
    <>
      <ImageBgPageWrapper>
        <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
          {renderDesktopAndTablet()}
        </ShowOn>
        <ShowOn largeMobile smallMobile>
          {renderMobile()}
        </ShowOn>
      </ImageBgPageWrapper>
    </>
  );
});

export default StatsPage;
