import { ReactElement } from 'react';
import { ExecutiveDto } from '../../types/Types';
import { Link } from 'react-router-dom';
import { getURI } from '../../api/helper';
import styles from './ExecutivesSmallTile.module.scss';
import { getFullExecutiveName } from '../../helpers/helpers';
import { DEFAULT_USER_PHOTO } from '../../constants/constants';

type ExecutiveSmallTileProps = {
  executive: ExecutiveDto;
  season?: string;
};

function ExecutiveSmallTile({
  executive,
  season,
}: ExecutiveSmallTileProps): ReactElement {
  const { id, attributes } = executive;

  return (
    <Link
      className={styles.wrapper}
      to={
        attributes?.is_catastrophe
          ? `/catastrophe/${attributes?.type}/${id}`
          : `/${attributes?.type}/${id}${season ? `?season=${season}` : ''}`
      }
      reloadDocument
    >
      <img
        className={styles.image}
        src={
          attributes?.photo?.data?.attributes?.url
            ? getURI(attributes?.photo?.data?.attributes?.url)
            : getURI(DEFAULT_USER_PHOTO)
        }
        alt='executive small'
      />
      <div className={styles.info}>
        <p className={styles.fullname}>{getFullExecutiveName(executive)}</p>
        <p className={styles.position}>{attributes?.position ?? ''}</p>
      </div>
    </Link>
  );
}

export default ExecutiveSmallTile;
