import { getURI } from 'api/helper';
import { AdvertisingWidgetDto } from 'types/Types';
import styles from './MobileGameTypeWidget.module.scss';
import ShowOn from 'components/core/adaptivity/ShowOn';
import classNames from 'classnames';
import ProtocolBannerLeftBlock from 'assets/ProtocolBannerLeftBlock';
import ProtocolBannerLeftLine from 'assets/ProtocolBannerLeftLine';
import ProtocolBannerRightLine from 'assets/ProtocolBannerRightLine';
import ProtocolBannerRightBlock from 'assets/ProtocolBannerRightBlock';
import Colors from 'constants/colors';
type MobileGameTypeWidgetType = {
  advertising: AdvertisingWidgetDto;
};
function formatDate(date: Date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}

function MobileGameTypeWidget({ advertising }: MobileGameTypeWidgetType) {
  return (
    <div className={styles.logoContainer}>
      <div className={styles.leftBlock}>
        <ShowOn largeDesktop smallDesktop largeTablet>
          <div className={styles.block}>
            <ProtocolBannerLeftBlock
              fillColor={
                advertising.attributes.game?.data?.attributes?.team_1?.data
                  ?.attributes?.main_color
              }
              height={46}
            />
          </div>
          <div className={styles.line}>
            <ProtocolBannerLeftLine
              fillColor={
                advertising.attributes.game?.data?.attributes?.team_1?.data
                  ?.attributes?.additional_color
              }
              height={46}
            />
          </div>
        </ShowOn>
      </div>
      <div className={styles.content}>
        <div className={styles.team}>
          <img
            src={getURI(
              advertising.attributes?.game?.data?.attributes?.team_1?.data
                ?.attributes.logo?.data?.attributes.url || ''
            )}
            className={styles.logo}
          />
          <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
            <div className={styles.teamTextWrapper}>
              <div className={styles.teamName}>
                {
                  advertising.attributes?.game?.data?.attributes?.team_1?.data
                    ?.attributes?.name
                }
              </div>
            </div>
          </ShowOn>
        </div>

        <div className={styles.textContainer}>
          <div className={styles.gameDateText}>
            {formatDate(
              new Date(advertising.attributes.game?.data.attributes.date || '')
            )}
          </div>
          <div className={styles.buyTicketsText}>Купить билет</div>
        </div>
        <div className={classNames(styles.team, styles.justifyAtEnd)}>
          <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
            <div className={styles.teamTextWrapper}>
              <div className={styles.teamName}>
                {
                  advertising.attributes?.game?.data?.attributes?.team_2?.data
                    ?.attributes?.name
                }
              </div>
            </div>
          </ShowOn>
          <img
            src={getURI(
              advertising.attributes?.game?.data?.attributes?.team_2?.data
                ?.attributes.logo?.data?.attributes.url || ''
            )}
            className={styles.logo}
          />
        </div>
      </div>
      <div className={styles.rightBlock}>
        <ShowOn largeDesktop smallDesktop largeTablet>
          <div className={styles.line}>
            <ProtocolBannerRightLine
              fillColor={
                advertising.attributes.game?.data?.attributes?.team_2?.data
                  ?.attributes?.additional_color
              }
              height={46}
            />
          </div>
          <div className={styles.block}>
            <ProtocolBannerRightBlock
              fillColor={
                advertising.attributes.game?.data?.attributes?.team_2?.data
                  ?.attributes?.main_color
              }
              height={46}
            />
          </div>
        </ShowOn>
      </div>
    </div>
  );
}

export default MobileGameTypeWidget;
